import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Autocomplete, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import type { FC } from 'react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapSearchStore } from 'stores/mapSearchStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { useScreenSize } from 'utils/useScreenSize'
import { getPropertyBounds } from '../../../utils/useMapCenteredOnProperties'
import { getMapOverlaysPadding } from '../CurrentValuesMap/_utils/getMapOverlaysPadding'
import { MapContext } from '../MapContext/MapContext'
import { AutoCompleteItem } from './AutoCompleteItem/AutoCompleteItem'
import { NarrowScreenSearch } from './NarrowScreenSearch'
import type { TSearchResultsItem } from './types'
import { WideScreenSearch } from './WideScreenSearch'
import { getSearchResultsAdministrativeAreaLevel1 } from './_utils/getSearchResultsAdministrativeAreaLevel1'
import { getSearchResultsCountries } from './_utils/getSearchResultsCountries'
import { getSearchResultsOrganizations } from './_utils/getSearchResultsOrganizations'
import { generatePropertyLabel, getSearchResultsProperties } from './_utils/getSearchResultsProperties'

const MAX_PROPERTIES_FOR_EMPTY_SEARCH_STATE = 50

export const MapSearch: FC = () => {
  const [searchText, setSearchText] = useState('')
  const [opened, { open, close }] = useDisclosure(false)
  const [lastSearchSelection, setLastSearchSelection] = useState<TSearchResultsItem | null>(null)
  const { isWideScreen, screenWidth } = useScreenSize()
  const theme = useMantineTheme()
  const { map } = useContext(MapContext)
  const width = isWideScreen ? 310 : screenWidth
  const height = 45
  const properties = fieldAssetStore.useSelector((s) => s?.properties ?? {})
  const selectedProperty = selectedFieldAssetsStore.useSelector((s) => s?.property)
  const highlightedPropertyIds = mapSearchStore.useSelector((s) => s.highlightedPropertyIds)
  const previousSearches = mapSearchStore.useSelector((s) => s.previousSearches)
  const searchRef = useRef<HTMLInputElement>(null)

  const defaultPropertyListSearches = getSearchResultsProperties({ properties, searchText: '' }).slice(
    0,
    MAX_PROPERTIES_FOR_EMPTY_SEARCH_STATE,
  )

  const defaultSearches = previousSearches.concat(defaultPropertyListSearches)

  useEffect(() => {
    if (opened && !isWideScreen) {
      setTimeout(() => searchRef?.current?.focus(), 50)
    }
  }, [opened])

  useEffect(() => {
    const lastSearchSelectionIsProbablyStillSelected = lastSearchSelection?.propertyIds.every(
      (pId) => highlightedPropertyIds[pId],
    )

    if (!lastSearchSelectionIsProbablyStillSelected) {
      setSearchText('')
    }
  }, [lastSearchSelection, highlightedPropertyIds, selectedProperty])

  useEffect(() => {
    if (!searchText) mapSearchStore.setState((s) => ({ ...s, highlightedPropertyIds: [] }))
  }, [searchText])

  const autocompleteData = useMemo(() => {
    if (!searchText) return defaultSearches

    const propertiesData = getSearchResultsProperties({ properties, searchText })
    const organizationsData = getSearchResultsOrganizations({ properties, searchText })
    const countriesData = getSearchResultsCountries({ properties, searchText })
    const administrativeAreaLevel1Data = getSearchResultsAdministrativeAreaLevel1({ properties, searchText })
    const propertiesResults = !!propertiesData.length
    const organizationsResults = !!organizationsData.length
    const countriesResults = !!countriesData.length
    const administrativeAreaLevel1Results = !!administrativeAreaLevel1Data.length

    const uniqueCategoriesOfResults =
      +propertiesResults + +organizationsResults + +countriesResults + +administrativeAreaLevel1Results

    const sliceLimit = uniqueCategoriesOfResults > 1 ? 3 : 10

    const results = [
      ...propertiesData.slice(0, sliceLimit),
      ...organizationsData.slice(0, sliceLimit),
      ...administrativeAreaLevel1Data.slice(0, sliceLimit),
      ...countriesData.slice(0, sliceLimit),
    ]

    if (!!results.length) {
      results.push(...previousSearches)
    }

    return results
  }, [searchText])

  useEffect(() => {
    // if user select property from the map, show property name in the search box
    if (selectedProperty && properties[selectedProperty] && !searchText) {
      setSearchText(
        generatePropertyLabel({
          propertyId: properties[selectedProperty].propertyId,
          propertyName: properties[selectedProperty].propertyName,
        }),
      )
    }
  }, [selectedProperty])

  const SearchToUse = isWideScreen ? WideScreenSearch : NarrowScreenSearch

  if (isUserOnlyAFreeRegionalUser()) {
    return null
  }

  return (
    <SearchToUse opened={opened} open={open} close={close} setSearchText={setSearchText}>
      <Autocomplete
        ref={searchRef}
        limit={1000}
        data={autocompleteData}
        nothingFound={
          !!searchText &&
          translate.phrases.banyanApp('Sorry… no properties were found matching that criteria')
        }
        itemComponent={AutoCompleteItem}
        onDropdownClose={() => {
          /**
           * on non-wide screens, when the search input is blurred, the
           * user can be left with a blank screen. Let's force the
           * dropdown to always be open on non wide screens
           */
          if (!isWideScreen) searchRef?.current?.click()
        }}
        icon={
          isWideScreen ? <FontAwesomeIcon icon={faSearch} size="lg" color={theme.colors.grey[3]} /> : <div />
        }
        rightSection={
          <ActionIcon
            onClick={() => {
              setSearchText('')

              searchRef?.current?.focus()
            }}
          >
            {!searchText.length ? null : <CloseIcon size={12} />}
          </ActionIcon>
        }
        onChange={setSearchText}
        filter={(value) => searchStringFromStringWithSpaces(value, searchText)}
        onItemSubmit={(item: TSearchResultsItem) => {
          const { propertyIds } = item
          const propertyBounds = getPropertyBounds(propertyIds[0])

          if (!isEmpty(propertyBounds)) {
            map?.fitBounds(propertyBounds, getMapOverlaysPadding())
          }

          const zoomLevel = map?.getZoom()

          serviceCenterStore.actions.setPropertyOverviewZoomLevel(zoomLevel)

          setSelectedFieldAsset({ property: propertyIds[0] })

          if (!!propertyIds?.length) {
            mapSearchStore.setState((s) => {
              const newPreviousSearchToAdd: TSearchResultsItem = {
                ...item,
                group: translate.phrases.banyanApp('Recently Searched'),
                type: 'PREVIOUS_SEARCH',
                value: `${item.type === 'PREVIOUS_SEARCH' ? '' : 'PREVIOUS_SEARCH-'}${item.value}`,
              }

              const oldSearchToUse = s.previousSearches.filter(
                (ps) => ps.value !== newPreviousSearchToAdd.value,
              )

              const newPreviousSearches = [newPreviousSearchToAdd].concat(oldSearchToUse.slice(0, 2))

              return {
                ...s,
                previousSearches: newPreviousSearches,
                highlightedPropertyIds: propertyIds.reduce(
                  (a: Record<TFieldAssetKeyTypes.TPropertyId, true>, b: TFieldAssetKeyTypes.TPropertyId) => {
                    a[b] = true

                    return a
                  },
                  {},
                ),
              }
            })
          }

          setLastSearchSelection(item)

          setSearchText(item.label)

          close()
        }}
        placeholder={translate.phrases.banyanApp('Search')}
        spellCheck="false"
        styles={{
          input: isWideScreen
            ? {
                width,
                height,
              }
            : {
                width,
                height,
                'backgroundColor': colors.grey50,
                'borderWidth': 0,
                'borderRadius': 0,
                '&:focus': {
                  border: '0px',
                  boxShadow: '0px 0px 0px white',
                },
              },
          dropdown: !isWideScreen
            ? {
                width: '100%',
                overflow: 'auto',
                borderColor: 'white',
                borderWidth: 0,
                height: 'calc(100vh - 65px)',
              }
            : {
                width,
                maxHeight: 'min(400px, calc(100vh - 200px))',
                overflow: 'auto',
              },
        }}
        value={searchText}
        withinPortal
        enterKeyHint="search"
        autoFocus={!isWideScreen}
      />
    </SearchToUse>
  )
}
