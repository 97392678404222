import { Grid } from '@mantine/core'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import type { TActiveNode, TPlannedNode } from 'App/ServiceCenter/types'
import { translate } from 'i18n/i18n'
import React from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { isPlannedNode } from '../../../utils/isPlannedNode'
import { renderWithDefaultValue } from '../../../utils/renderers'

export const TargetPest = () => {
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)
  const allInsects = fieldAssetStore.useSelector((s) => s.insects)

  if (!selectedNode || !allInsects || !(selectedNode as TActiveNode)?.nodeType) return null

  let targetInsectId = null

  if (isPlannedNode(selectedNode)) {
    targetInsectId = (selectedNode as TPlannedNode).insectId
  } else {
    targetInsectId = (selectedNode as TActiveNode).trap?.targetInsectId
  }

  const targetInsect = targetInsectId ? allInsects[targetInsectId]?.name : null

  return (
    <Grid css={{ fontSize: '14px', marginTop: 8 }}>
      <Grid.Col span={4}>{translate.phrases.placeholder('Target Pest ')}</Grid.Col>
      <Grid.Col span={8} css={{ fontWeight: 500 }}>
        {renderWithDefaultValue(targetInsect, translate.phrases.placeholder('N/A'))}
      </Grid.Col>
    </Grid>
  )
}
