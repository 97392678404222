import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { useContext, useMemo, useState } from 'react'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { sampleCollection } from '../../utils/sampleCollection'
import { AlmaContext } from '../AlmaContext/AlmaContext'
import { AlmaMessage } from '../AlmaMessage/AlmaMessage'
import { AlmaPill } from '../AlmaPill/AlmaPill'
import { CannedResponse } from '../CannedResponse/CannedResponse'
import { CustomerMessage } from '../CustomerMessage/CustomerMessage'

export const PropertySelection = () => {
  const [showSelection, setShowSelection] = useState(true)
  const { properties, setChatLog, scrollToBottomOfChat } = useContext(AlmaContext)

  const randomProperties = useMemo<TProperty[]>(() => {
    const selectedPropertyId = selectedFieldAssetsStore.getState().property

    const selectedProperty =
      selectedPropertyId && selectedPropertyId in properties ? properties[selectedPropertyId] : null

    if (selectedProperty) {
      return [selectedProperty].concat(
        sampleCollection({
          collection: Object.values(properties),
          maxSize: 3,
        }),
      )
    } else {
      return sampleCollection({
        collection: Object.values(properties),
        maxSize: 4,
      })
    }
  }, [])

  return (
    <>
      <AlmaMessage showAvatar textContent="Which property would you like to start with?" />
      {showSelection && (
        <div
          css={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          {randomProperties.map((property) => (
            <AlmaPill
              key={property.propertyId}
              textContent={property.propertyName}
              detail={property}
              onClick={(property) => {
                setShowSelection(false)

                setChatLog?.((log) => [
                  ...log,
                  <CustomerMessage textContent={property.propertyName} />, // eslint-disable-line react/jsx-key
                  <CannedResponse selectedProperty={property} />, // eslint-disable-line react/jsx-key
                ])

                scrollToBottomOfChat?.()
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}
