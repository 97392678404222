import { sampleCollection } from 'App/Map/AlmaChat/utils/sampleCollection'
import type { TNavigationKey } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'
import { generateFlatNavigationList } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'

export const generateRandomLayers = (maxSize = 4) => {
  const flatNavigationList = generateFlatNavigationList()

  let layers = []

  for (const key in flatNavigationList) {
    const navigationItem = flatNavigationList[key as TNavigationKey]

    if (navigationItem) {
      const childLayers = navigationItem?.childLayers

      if (childLayers) {
        layers.push(
          ...Object.values(childLayers).map((childLayer) => ({
            key: childLayer.key,
            translatedTitle: childLayer.translatedTitle,
          })),
        )
      } else {
        layers.push({ key: navigationItem.key, translatedTitle: navigationItem.translatedTitle })
      }
    }
  }

  return sampleCollection({
    collection: layers,
    maxSize,
  })
}
