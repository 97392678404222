import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
  TValuesCurrentRegionValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { smallStore } from 'stores/smallStore'
import { checkAuthorization } from 'utils/checkAuthorization'

export const TemperatureSecondaryDropdown = () => {
  const hasInversionPermission = checkAuthorization({
    entity: 'FOR_ANY_ENTITY',
    permission: 'VIEW_INVERSION_DETAILS',
  })

  const showPropertyPlayback = smallStore.useSelector((s) => s.showPropertyPlayback)

  const updateMapVisuals = (
    newValueType: TValuesCurrentPointsValueTypes | TValuesCurrentHeatmapPointsValueTypes,
  ) => {
    const newValueTypeMidMedMax =
      newValueType === 'temperature_in_c' ? 'temperature_minMedianMax_in_c' : 'inversion'

    const outOfBlockValueType = newValueType === 'temperature_in_c' ? 'temperature_out_c' : null

    mapControlsStore.setState((s) => ({
      ...s,
      manualExtremes: false,
      mapVisualValueGroup: {
        ...s.mapVisualValueGroup,
        [MAP_VISUAL.POINT]: {
          ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
          air_temperature: {
            ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].air_temperature ?? { mItem: 'M_InBlockPoint' }),
            valueType: newValueType as TValuesCurrentPointsValueTypes,
            outOfBlockValueType,
          },
        },
        [MAP_VISUAL.PROPERTY]: {
          ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
          air_temperature: {
            ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].air_temperature ?? { mItem: 'M_Property' }),
            valueType: newValueTypeMidMedMax as TValuesCurrentPropertiesValueTypes,
          },
        },
        [MAP_VISUAL.REGION]: {
          ...s.mapVisualValueGroup[MAP_VISUAL.REGION],
          air_temperature: {
            ...(s.mapVisualValueGroup[MAP_VISUAL.REGION].air_temperature ?? { mItem: 'M_Region' }),
            valueType: newValueTypeMidMedMax as TValuesCurrentRegionValueTypes,
          },
        },
        [MAP_VISUAL.BLOCK]: {
          ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
          air_temperature: {
            ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].air_temperature ?? { mItem: 'M_Block' }),
            valueType: newValueTypeMidMedMax as TValuesCurrentBlocksValueTypes,
          },
        },
        [MAP_VISUAL.SCD]: {
          ...s.mapVisualValueGroup[MAP_VISUAL.SCD],
          air_temperature: {
            ...(s.mapVisualValueGroup[MAP_VISUAL.SCD].air_temperature ?? { mItem: 'M_SCD' }),
            valueType: newValueType as TValuesCurrentHeatmapPointsValueTypes,
          },
        },
      },
    }))
  }

  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.air_temperature?.valueType,
  )

  if (!hasInversionPermission || showPropertyPlayback) {
    return null
  }

  const items = [
    {
      value: 'temperature_in_c',
      label: translate.phrases.banyanApp('Air Temperature'),
    },
    {
      value: 'inversion',
      label: translate.phrases.banyanApp('Temperature Inversion'),
    },
  ]

  return (
    <Select
      data={items}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
