import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { isSemiosEmployeeOrTester } from 'utils/isSemiosEmployeeOrTester'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { sortByKey } from 'utils/sortByKey'
import type { TSearchResultsItem } from '../types'

export const generatePropertyLabel = (o: { propertyName: string; propertyId: number }) => {
  return isSemiosEmployeeOrTester() ? `${o.propertyName} (${o.propertyId})` : o.propertyName
}

export const getSearchResultsProperties = ({
  properties,
  searchText,
}: {
  properties: NonNullable<routes.UserAppStartup.Response['properties']>
  searchText: string
}): TSearchResultsItem[] => {
  const propertyResults: { propertyName: string; propertyId: TFieldAssetKeyTypes.TPropertyId }[] = []

  Object.values(properties).forEach(({ propertyId, propertyName }) => {
    if (searchStringFromStringWithSpaces(searchText, generatePropertyLabel({ propertyId, propertyName }))) {
      propertyResults.push({ propertyId, propertyName })
    }
  })

  return propertyResults.sort(sortByKey('propertyName')).map((p) => ({
    type: 'PROPERTY',
    label: generatePropertyLabel({ propertyId: p.propertyId, propertyName: p.propertyName }),
    propertyIds: [p.propertyId],
    value: `PROPERTY-${p.propertyName}`,
    group: translate.phrases.templates('{{label}} ({{count}})', {
      label: translate.phrases.banyanApp('Property'),
      count: propertyResults.length,
    }),
  }))
}
