import { useDebouncedState, useDisclosure } from '@mantine/hooks'
import { useCallback, useEffect, useRef } from 'react'
import { alphaColorReplacer, colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { Label } from '../../Label/Label'
import type { REGION_META, TRegionsValueTypesToPropsDictionary } from '../RegionsValuesCache'

export type TRegionBadgeComponentProps = {
  meta: REGION_META
  valueTypesToPropsDictionary: TRegionsValueTypesToPropsDictionary
}

export const RegionBadgeComponent = (props: TRegionBadgeComponentProps) => {
  const primaryValueGroup = usePrimaryValueGroup()
  const isUserFreeRegionalUser = isUserOnlyAFreeRegionalUser()

  const onRegionBadgeClick = useCallback(() => {
    isUserFreeRegionalUser && setSelectedFieldAsset({ region: props.meta.regionId })
  }, [])

  const { loadingCurrentValues, valueType, showMapBadgeNames } = mapControlsStore.useSelector((s) => ({
    loadingCurrentValues: s.loadingCurrentValues,
    valueType: !primaryValueGroup ? null : s.mapVisualValueGroup.REGION[primaryValueGroup]?.valueType,
    showMapBadgeNames: s.showMapBadgeNames,
  }))

  // TODO: this is a hack, as you probably guessed. It seems that the maps cache has a lag in updating the props. This will
  const [loadingCurrentValuesDebounced, setLoadingCurrentValuesDebounced] = useDebouncedState(
    loadingCurrentValues,
    1,
  )

  useEffect(() => {
    setLoadingCurrentValuesDebounced(loadingCurrentValues)
  }, [loadingCurrentValues])

  const zoom = mapStore.useSelector((s) => s.zoom)
  const [isHovered, { close, open }] = useDisclosure(false)

  useEffect(() => {
    close()
  }, [zoom])

  const ref = useRef<HTMLDivElement>(null)
  const isSelected = selectedFieldAssetsStore.useSelector((s) => s.region === props.meta.regionId)

  useSetMapOverlayZIndex({
    baseZIndex: 0,
    ref,
    isHighlighted: isSelected,
    mItem: 'M_Region',
  })

  const containerCSS = props.valueTypesToPropsDictionary?.[String(valueType)]?.getContainerCSS?.() ?? {}
  const color = (containerCSS.backgroundColor as string) ?? colors.midnight
  const border = alphaColorReplacer(colors.midnight, 0.8) //getContrastColorFromHeatmapColor(color)

  if (loadingCurrentValues || loadingCurrentValuesDebounced) return null

  const fontSize = zoom < 8 ? 30 : 40

  return (
    <div
      css={{
        marginLeft: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      ref={ref}
      onClick={onRegionBadgeClick}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {(isHovered || showMapBadgeNames) && <Label bottom={fontSize + 3}>{props.meta.regionName}</Label>}
      {props.valueTypesToPropsDictionary?.[String(valueType)]?.children ? (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize,
            fontWeight: 'bold',
            color,
            WebkitTextStroke: `2px ${border}`,
            textShadow: `0px 0px 10px ${border}`,
            svg: {
              fontSize: fontSize * 0.5,
            },
          }}
        >
          {props.valueTypesToPropsDictionary?.[String(valueType)]?.children}
        </div>
      ) : null}
    </div>
  )
}
