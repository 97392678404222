import { Popover, Switch } from '@mantine/core'
import { IconQuestionMark } from 'components/icons/IconQuestionMark'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'
import { updateUserProfile } from 'utils/updateUserProfile'
import { useViewportSizeWithCorrectInitialState } from 'utils/useViewportSizeWithCorrectInitialState'

const RegionalDataSwitch = () => {
  const { showRegionalData, showRegionalDataTooltip } = smallStore.useSelector((s) => ({
    showRegionalData: s.showRegionalData,
    showRegionalDataTooltip: s.showRegionalDataTooltip,
  }))

  const { width } = useViewportSizeWithCorrectInitialState()
  const fullSizePopover = width < 660

  const handleShowRegionalDataChange = (newValue: boolean) => {
    smallStore.setState((s) => ({ ...s, showRegionalData: newValue }))

    try {
      updateUserProfile({ showRegionalData: newValue })
    } catch {}
  }

  return (
    <>
      <Switch
        label={translate.phrases.banyanApp('Regional Data')}
        checked={showRegionalData}
        onChange={(e) => handleShowRegionalDataChange(e.currentTarget.checked)}
        styles={{
          label: { color: 'white', cursor: 'unset', fontSize: 16 },
          track: {
            width: 36,
            height: 20,
            cursor: 'unset',
            backgroundColor: showRegionalData ? `${colors.green} !important` : undefined,
            borderColor: showRegionalData ? `${colors.green} !important` : undefined,
          },
        }}
      />
      <Popover
        position={fullSizePopover ? undefined : 'right'}
        withArrow
        shadow="md"
        opened={showRegionalDataTooltip}
        radius={5}
        width={fullSizePopover ? '100%' : 452}
        styles={{
          dropdown: {
            position: 'fixed',
          },
        }}
      >
        <Popover.Target>
          <div
            onMouseEnter={() => smallStore.setState((prev) => ({ ...prev, showRegionalDataTooltip: true }))}
            onMouseLeave={() => smallStore.setState((prev) => ({ ...prev, showRegionalDataTooltip: false }))}
            css={{ marginLeft: 10 }}
          >
            <IconQuestionMark />
          </div>
        </Popover.Target>
        <Popover.Dropdown
          css={{ pointerEvents: 'none', color: colors.midnight, fontSize: 14, fontWeight: '400' }}
        >
          <div>
            {translate.phrases.banyanApp('Show/hide regional data on the map, Summary and Details view')}
          </div>
        </Popover.Dropdown>
      </Popover>
    </>
  )
}

export default RegionalDataSwitch
