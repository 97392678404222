import { routes } from '@semios/app-platform-banyan-route-definitions'
import { MapContext } from 'App/Map/MapContext/MapContext'
import { useContext, useEffect, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { apiFetch } from '../apiFetch'
import { buildOverlay } from './buildOverlay'

export const useSelectedRegionBoundary = () => {
  const { map } = useContext(MapContext)
  const selectedRegion = selectedFieldAssetsStore.useSelector((s) => s.region)

  const [overlaysDictionary, setOverlaysDictionary] = useState<
    Record<string, { show: () => void; hide: () => void } | null>
  >({})

  const regionsGeometries = fieldAssetStore.useSelector((s) => s.regionsGeometries)
  const showRegionalData = smallStore.useSelector((s) => s.showRegionalData)

  useEffect(() => {
    if (selectedRegion) {
      if (!regionsGeometries[selectedRegion]) {
        apiFetch<
          routes.LandingPageFieldAssetConfigurations.Request,
          routes.LandingPageFieldAssetConfigurations.Response
        >({
          url: routes.LandingPageFieldAssetConfigurations.path,
          body: { regions: { regionIds: [selectedRegion], _metaData: { geometry: true } } },
        }).then((res) => {
          if (res?.data?.regions?.[selectedRegion]?.geometry) {
            fieldAssetStore.setState((s) => ({
              ...s,
              regionsGeometries: {
                ...s.regionsGeometries,
                [selectedRegion]: String(res?.data?.regions?.[selectedRegion]?.geometry),
              },
            }))
          }
        })
      }
    }
  }, [selectedRegion, regionsGeometries[String(selectedRegion)]])

  useEffect(() => {
    Object.entries(overlaysDictionary)
      .filter(([regionId]) => regionId !== selectedRegion)
      .forEach(([, overlay]) => overlay?.hide())

    if (map && selectedRegion && regionsGeometries?.[selectedRegion]) {
      if (overlaysDictionary[selectedRegion]) {
        !showRegionalData
          ? overlaysDictionary[selectedRegion]?.hide()
          : overlaysDictionary[selectedRegion]?.show()
      } else {
        setOverlaysDictionary((d) => ({
          ...d,
          [selectedRegion]: buildOverlay({
            map,
            multiPolygonGeoJSONString: regionsGeometries?.[selectedRegion],
          }),
        }))
      }
    }
  }, [
    selectedRegion,
    regionsGeometries?.[String(selectedRegion)],
    overlaysDictionary[String(selectedRegion)],
    map,
    showRegionalData,
  ])
}
