import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import type { TSelectedLayer } from '../../AlmaContext/AlmaContext'

export const navigatePerSelection = ({
  layer,
  property,
  dateFrom,
  dateTo,
  llmResponse,
}: {
  layer: Exclude<TSelectedLayer, null>
  property: TProperty
  dateFrom: string
  dateTo: string
  llmResponse: routes.AlmaChatLlm.Response | null
}) => {
  let selectedValueGroups = {
    [layer.key as TValueGroup]: +new Date(),
  }

  if (llmResponse?.views?.includes(layer.key as TValueGroup)) {
    for (const key of llmResponse.views) {
      selectedValueGroups[key] = +new Date()
    }
  }

  selectedValueGroupsStore.setState((s) => ({
    ...s,
    primaryValueGroup: layer.key as TValueGroup,
    selectedValueGroups,
  }))

  setSelectedFieldAsset({ property: property.propertyId })

  detailsPanelStore.setState((s) => ({
    ...s,
    detailsTab: 'DETAILS',
    isForcedOpen: true,
    dateFrom,
    dateTo,
  }))
}
