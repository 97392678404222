import { isNil } from '@semios/app-platform-common'
import { EAggregationInterval } from 'App/Map/types'
import { updateTooltipContents } from 'components/StackedChart/_utils/lineChartTooltipFormatter/updateTooltipContents'
import type { TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { smallStore } from 'stores/smallStore'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'

export const VALUE_SOURCE_ICON_NOT_FROM_DEVICE_MAIN_COLOR = '#979797'

export const VALUE_SOURCE_ICON_NOT_FROM_DEVICE_FOR_HIGHCHARTS = `
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect
    x="0.792969"
    y="0.273621"
    width="21.1878"
    height="21.1878"
    rx="3"
    fill="${VALUE_SOURCE_ICON_NOT_FROM_DEVICE_MAIN_COLOR}"
  />
  <circle cx="4.30419" cy="8.59416" r="1.66283" fill="#131313" />
  <circle cx="12.4924" cy="4.4251" r="1.66283" fill="#131313" />
  <circle cx="18.4695" cy="12.9864" r="1.66283" fill="#131313" />
  <path
    d="M13.6783 14.3944C14.3019 14.1112 15.5491 13.0479 15.5491 11.0608C15.5491 9.07366 14.3019 7.92322 13.6783 7.59639"
    stroke="black"
  />
  <path
    d="M8.5544 14.3944C7.9308 14.1112 6.68359 13.0479 6.68359 11.0608C6.68359 9.07366 7.9308 7.92322 8.5544 7.59639"
    stroke="black"
  />
  <path
    d="M9.46414 9.45978C9.19135 9.59343 8.64575 10.0951 8.64575 11.0328C8.64575 11.9704 9.19135 12.5133 9.46414 12.6675"
    stroke="black"
  />
  <path
    d="M12.9511 9.45978C13.2239 9.59343 13.7695 10.0951 13.7695 11.0328C13.7695 11.9704 13.2239 12.5133 12.9511 12.6675"
    stroke="black"
  />
  <path
    d="M8.32959 18.2333L11.0932 11.5515C11.1275 11.4685 11.2454 11.4694 11.2786 11.5528L13.9312 18.2333"
    stroke="black"
  />
</svg>`

export const questionMarkIcon = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 17H9V15H11V17ZM13.07 9.25L12.17 10.17C11.45 10.9 11 11.5 11 13H9V12.5C9 11.4 9.45 10.4 10.17 9.67L11.41 8.41C11.78 8.05 12 7.55 12 7C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7H6C6 4.79 7.79 3 10 3C12.21 3 14 4.79 14 7C14 7.88 13.64 8.68 13.07 9.25Z" fill="#464B53"/>
</svg>
`

const getIconLegend = () => {
  const iconLegend = `<tr><td colspan="4"><hr style="border: 1px solid ${colors.grey200};"/></td></tr>`
  const text = translate.phrases.banyanApp('Device Data unavailable')

  return `${iconLegend} <tr><td colspan="4"><span style="display: flex; align-items: center">${VALUE_SOURCE_ICON_NOT_FROM_DEVICE_FOR_HIGHCHARTS}&nbsp;&nbsp;${text}</span></td></tr>`
}

const getTooltipValueSource = ({
  point,
  seriesId,
  content,
  firstForecastTimestamp = +new Date(),
  isCompareSeason = false,
  isVirtual = false,
}: {
  point: TooltipFormatterContextObject | undefined
  seriesId: string | undefined
  content: string
  firstForecastTimestamp?: number
  isCompareSeason?: boolean
  isVirtual?: boolean
}) => {
  if (!seriesId) return ''

  const { showDataSource } = smallStore.getState()
  const isDataForecasted = !isCompareSeason ? Number(point?.x) >= firstForecastTimestamp : false

  const shouldShowDataSourceIcon =
    !isVirtual &&
    //@ts-ignore
    !point?.point?.isDeviceReported &&
    !isNil(point?.point?.y) &&
    showDataSource &&
    !isDataForecasted

  const tooltipValue = shouldShowDataSourceIcon
    ? `${VALUE_SOURCE_ICON_NOT_FROM_DEVICE_FOR_HIGHCHARTS} ${content}`
    : content

  return isNil(content) ? translate.phrases.templates('-') : tooltipValue
}

export const updateTooltipForValueSource = ({
  seriesIds,
  compareSeasonIds,
  regionSeriesIds = [],
  points,
  tooltipContents,
  firstForecastTimestamp,
  isVirtual = false,
}: {
  seriesIds: Array<string>
  compareSeasonIds: Array<string>
  regionSeriesIds?: Array<string>
  points: Array<TooltipFormatterContextObject> | undefined
  tooltipContents: string
  firstForecastTimestamp: number
  isVirtual?: boolean
}) => {
  let shouldShowLegendOnTooltip = false

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const { showDataSource } = smallStore.getState()
  const aggregatedBy = chooseAmongstUnAggHourlyAndDaily()

  seriesIds.forEach((seriesId, index) => {
    if (!seriesId) return

    const isRegionSeries = regionSeriesIds.includes(seriesId)
    const point = points?.find((point) => point.series.options.id === seriesId)
    const compareSeasonPoint = points?.find((point) => point.series.options.id === compareSeasonIds[index])

    if (!isRegionSeries && !shouldShowLegendOnTooltip && !isVirtual) {
      const isDataForecasted = Number(point?.x) >= firstForecastTimestamp

      if (compareSeasonsInterval === 0) {
        //@ts-ignore
        shouldShowLegendOnTooltip = !point?.point?.isDeviceReported && !isDataForecasted
      } else {
        shouldShowLegendOnTooltip =
          //@ts-ignore
          (!point?.point?.isDeviceReported && !isDataForecasted) ||
          //@ts-ignore
          !compareSeasonPoint?.point?.isDeviceReported
      }
    }

    tooltipContents = updateTooltipContents({
      seriesId: seriesId,
      tooltipContents,
      fieldsToChange: {
        value: ({ content }) =>
          getTooltipValueSource({
            point,
            seriesId,
            content,
            firstForecastTimestamp,
            isVirtual: isRegionSeries ? true : isVirtual,
          }),
        compareSeasonsValue: ({ content }) =>
          getTooltipValueSource({
            point: compareSeasonPoint,
            seriesId: compareSeasonIds[index],
            content,
            firstForecastTimestamp,
            isCompareSeason: true,
            isVirtual: isRegionSeries ? true : isVirtual,
          }),
      },
    })
  })

  if (shouldShowLegendOnTooltip && showDataSource && aggregatedBy !== EAggregationInterval.DAILY) {
    tooltipContents += getIconLegend()
  }

  return tooltipContents
}
