import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNestedLatLngArray } from 'App/Map/_utils/addMultiPolygonToMap'

export const makeBlockBounds = (
  map: google.maps.Map,
  propertyBlocks: {
    [blockId: string]: routes.UserAppStartup.TFieldAssetValueTypes.TBlock
  },
  centroidCoordinates?: {
    lat: number
    lng: number
  },
) => {
  try {
    Object.values(propertyBlocks).forEach((block: routes.UserAppStartup.TFieldAssetValueTypes.TBlock) => {
      const { geometry: polygonString } = block

      const { coordinates } = JSON.parse(polygonString) as {
        coordinates: Array<Array<number[] | Array<number[]>>>
      }

      const blockBounds = new google.maps.LatLngBounds()

      const blockLatLngs = coordinates[0].reduce((all, values) => {
        if (!isNestedLatLngArray(values)) {
          const [lng, lat] = values

          blockBounds.extend({ lat, lng })

          all.push(new google.maps.LatLng({ lat, lng }))
        } else {
          const latLngs = values.map(([lng, lat]) => {
            blockBounds.extend({ lat, lng })

            return new google.maps.LatLng({ lat, lng })
          })

          all.push(...latLngs)
        }

        return all
      }, [] as google.maps.LatLng[])

      const polygon = new google.maps.Polygon({ paths: blockLatLngs, map })

      polygon.setOptions({
        strokeColor: 'white',
        strokeWeight: 2,
        fillColor: 'none',
      })

      if (centroidCoordinates) {
        const { lng, lat } = centroidCoordinates

        new google.maps.Marker({
          position: { lat, lng },
          map,
        })
      }
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
}
