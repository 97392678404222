import { AlmaIcon } from 'App/Map/AlmaChat/components/AlmaIcon/AlmaIcon'
import { AlmaInput } from 'App/Map/AlmaChat/components/AlmaInput/AlmaInput'
import { AlmaMessage } from 'App/Map/AlmaChat/components/AlmaMessage/AlmaMessage'
import { AlmaPill } from 'App/Map/AlmaChat/components/AlmaPill/AlmaPill'
import { Body } from 'App/Map/AlmaChat/components/Body/Body'
import { CloseButton } from 'App/Map/AlmaChat/components/CloseButton/CloseButton'
import { CustomerMessage } from 'App/Map/AlmaChat/components/CustomerMessage/CustomerMessage'
import { Head } from 'App/Map/AlmaChat/components/Head/Head'
import { Tail } from 'App/Map/AlmaChat/components/Tail/Tail'
import { ThumbDownIcon } from 'App/Map/AlmaChat/components/ThumbDownIcon/ThumbDownIcon'
import { ThumbUpIcon } from 'App/Map/AlmaChat/components/ThumbUpIcon/ThumbUpIcon'
import { translate } from 'i18n/i18n'

const Confirmation = () => (
  <div>
    <div
      css={{
        display: 'flex',
        marginRight: '10%',
      }}
    >
      <div
        css={{
          backgroundColor: 'rgba(230, 222, 255, 1)',
          padding: '10px',
          borderRadius: '0 15px 15px 15px',
          position: 'relative',
        }}
      >
        <div
          css={{
            position: 'absolute',
            left: '-28px',
            top: '0',
          }}
        >
          <AlmaIcon />
        </div>
        You want to view Codling Moth at Dorsing Creek for 14 Aug - 14 Sep, 2024?
        <div>
          <a href="javascript:void(0)">{translate.phrases.banyanApp('View Details')}</a>
        </div>
      </div>
    </div>
    <div
      css={{
        marginTop: '8px',
        display: 'flex',
        gap: '10px',
      }}
    >
      <ThumbUpIcon />
      <ThumbDownIcon />
    </div>
  </div>
)

export const AlmaChatExample = () => {
  return (
    <div
      css={{
        width: '420px',
      }}
    >
      <Head>
        <CloseButton />
      </Head>

      <Body>
        <AlmaMessage showAvatar textContent="Welcome to Alma!" />
        <AlmaMessage textContent="I’m here to help you to quickly get the information you are looking for." />
        <AlmaMessage textContent="Start by typing what you want to look at for a specific property or start with some of the easy topics below." />

        <div
          css={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          <AlmaPill textContent="Codling Moth" />
          <AlmaPill textContent="Temperature" />
          <AlmaPill textContent="Bee Hours" />
          <AlmaPill textContent="Irrigation Activity" />
        </div>

        <CustomerMessage textContent="Codling Moth" />

        <AlmaMessage showAvatar textContent="Which property would you like to start with?" />

        <div
          css={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          <AlmaPill textContent="Amaretto Almonds - Area 3 Lake Powell (51180)" />
          <AlmaPill textContent="Maverick Farming - Riverbench Vineyard (51135)" />
          <AlmaPill textContent="Dorsing Farms - Rd. C (50580)" />
          <AlmaPill textContent="Geoffrey Thompson Orchards - GT Coomboona (51612)" />
        </div>

        <Confirmation />

        <AlmaMessage showAvatar showEllipsis />
      </Body>

      <Tail>
        <AlmaInput />
      </Tail>
    </div>
  )
}
