import { closeAlmaChat } from '../../utils/closeAlmaChat'
import { CloseIcon } from '../CloseIcon/CloseIcon'

export const CloseButton = () => {
  return (
    <div
      css={{
        position: 'absolute',
        top: '15px',
        left: '15px',
        cursor: 'pointer',
      }}
      onClick={closeAlmaChat}
    >
      <CloseIcon />
    </div>
  )
}
