import { Capacitor } from '@capacitor/core'
import { Global } from '@emotion/react'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { Map } from 'App/Map/Map'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import { HubMaintenance } from 'components/HubMaintenance/HubMaintenance'
import { IntercomProvider } from 'components/Intercom/IntercomProvider'
import { ModalsProvider } from 'components/ModalsProvider/ModalsProvider'
import { NfcScanner } from 'components/NfcScanner/NfcScanner'
import { QrCodeScanner } from 'components/QrCodeScanner/QrCodeScanner'
import { I18nWrapper } from 'i18n/I18nWrapper'
import { useEffect } from 'react'
import { customTheme } from 'settings/ui-theme'
import { smallStore } from 'stores/smallStore'
import { apiTokenGetter } from 'utils/apiTokenGetter'
import { redirectRoute } from 'utils/redirectRoute'
import { Authentication } from './Authentication'
import { ResetPassword } from './Authentication/ResetPassword'
import { MapProvider } from './Map/MapContext/MapProvider'
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy'
import { SelfRegistration } from './SelfRegistration/SelfRegistration'
import { Sula } from './Sula/Sula'
import { Tac } from './Tac/Tac'
import { UIExamples } from './UIExamples/UIExamples'

const Router = () => {
  const pathname = location.pathname
  const token = apiTokenGetter()

  if (pathname.startsWith('/privacy')) {
    return <PrivacyPolicy />
  } else if (pathname.startsWith('/sula')) {
    return <Sula />
  } else if (pathname.startsWith('/tac')) {
    return <Tac />
  } else if (pathname.startsWith('/self-register')) {
    return <SelfRegistration />
  } else if (pathname.startsWith('/ui-examples')) {
    return <UIExamples />
  } else if ((pathname.startsWith('/reset') || pathname.startsWith('/choosepassword')) && !token) {
    return <ResetPassword />
  } else if (!token) {
    if (pathname === '/') {
      redirectRoute('/login')
    }

    return <Authentication />
  } else {
    return <Map />
  }
}

export const App = () => {
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (window.Capacitor.getPlatform() === 'android') {
        /*We don't display content edge to edge on Android so we don't need to account for the safe area
               https://developer.android.com/develop/ui/views/layout/edge-to-edge
               */
        smallStore.setState((s) => ({ ...s, safeArea: { insets: { top: 0, bottom: 0, left: 0, right: 0 } } }))
      } else if (window.Capacitor.getPlatform() === 'ios') {
        smallStore.setState((s) => ({ ...s, safeArea: { insets } }))
      }
    })

    SafeArea.addListener('safeAreaChanged', ({ insets }) => {
      if (window.Capacitor.getPlatform() === 'ios') {
        smallStore.setState((s) => ({ ...s, safeArea: { insets } }))
      }
    })

    // Safe-area plugin does not work on Web, so we have to manually set the safe area using CSS variables
    if (Capacitor.getPlatform() === 'web') {
      const insets = {
        top: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')),
        bottom: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')),
        left: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal')),
        right: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sar')),
      }

      smallStore.setState((s) => ({ ...s, safeArea: { insets } }))
    }
  }, [])

  return (
    <I18nWrapper>
      <Global
        styles={{
          '*,*::before,*::after': { boxSizing: 'border-box' },
          'body': {
            fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
            margin: 0,
            padding: 0,
          },
          ':root': {
            '--sat': 'env(safe-area-inset-top)',
            '--sar': 'env(safe-area-inset-right)',
            '--sab': 'env(safe-area-inset-bottom)',
            '--sal': 'env(safe-area-inset-left)',
          },
        }}
      />
      <MantineProvider theme={customTheme}>
        <MapProvider>
          <ErrorBoundary>
            <IntercomProvider>
              <Notifications position="top-center" css={{ top: 'env(safe-area-inset-top)', marginTop: 10 }} />
              <QrCodeScanner.Overlay />
              <NfcScanner.Overlay />
              <AboveAllModal.Overlay />
              <ModalsProvider>
                <HubMaintenance>
                  <Router />
                </HubMaintenance>
              </ModalsProvider>
            </IntercomProvider>
          </ErrorBoundary>
        </MapProvider>
      </MantineProvider>
    </I18nWrapper>
  )
}
