export const DATA_SOURCE_OVERLAY_BACKGROUND_COLOR = 'rgba(151, 151, 151, 0.7)'

export const DataSourceOverlay = ({ overlayCSS }: { overlayCSS?: React.CSSProperties }) => {
  return (
    <div
      css={{
        position: 'absolute',
        backgroundColor: DATA_SOURCE_OVERLAY_BACKGROUND_COLOR,
        ...overlayCSS,
      }}
    ></div>
  )
}
