export const UpArrowIcon = ({ isActive }: { isActive?: boolean }) => {
  if (isActive) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM16.707 10.707C16.5195 10.8945 16.2652 10.9998 16 10.9998C15.7348 10.9998 15.4805 10.8945 15.293 10.707L13 8.414V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V8.414L8.707 10.707C8.61476 10.8025 8.50441 10.8787 8.38241 10.9311C8.26041 10.9835 8.12919 11.0111 7.99641 11.0123C7.86363 11.0134 7.73195 10.9881 7.60905 10.9378C7.48615 10.8875 7.3745 10.8133 7.28061 10.7194C7.18672 10.6255 7.11246 10.5139 7.06218 10.391C7.0119 10.2681 6.9866 10.1364 6.98775 10.0036C6.98891 9.87082 7.01649 9.7396 7.0689 9.6176C7.12131 9.49559 7.19749 9.38525 7.293 9.293L11.293 5.293C11.4805 5.10553 11.7348 5.00021 12 5.00021C12.2652 5.00021 12.5195 5.10553 12.707 5.293L16.707 9.293C16.8945 9.48053 16.9998 9.73484 16.9998 10C16.9998 10.2652 16.8945 10.5195 16.707 10.707Z"
          fill="#000"
        />
      </svg>
    )
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM16.707 10.707C16.5195 10.8945 16.2652 10.9998 16 10.9998C15.7348 10.9998 15.4805 10.8945 15.293 10.707L13 8.414V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V8.414L8.707 10.707C8.61476 10.8025 8.50441 10.8787 8.38241 10.9311C8.26041 10.9835 8.12919 11.0111 7.99641 11.0123C7.86363 11.0134 7.73195 10.9881 7.60905 10.9378C7.48615 10.8875 7.3745 10.8133 7.28061 10.7194C7.18672 10.6255 7.11246 10.5139 7.06218 10.391C7.0119 10.2681 6.9866 10.1364 6.98775 10.0036C6.98891 9.87082 7.01649 9.7396 7.0689 9.6176C7.12131 9.49559 7.19749 9.38525 7.293 9.293L11.293 5.293C11.4805 5.10553 11.7348 5.00021 12 5.00021C12.2652 5.00021 12.5195 5.10553 12.707 5.293L16.707 9.293C16.8945 9.48053 16.9998 9.73484 16.9998 10C16.9998 10.2652 16.8945 10.5195 16.707 10.707Z"
        fill="#B4B3BB"
      />
    </svg>
  )
}
