import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 1

type TAirTemperatureUnit = 'c' | 'f'

type TCanopy = 'ABOVE' | 'IN' | 'BELOW' | 'IN_REGION'

const airTemperatureFunctionMaker =
  (canopy: TCanopy) =>
  (
    temperature: number | null = null,
    {
      decimalPlaces = DEFAULT_DECIMAL_PLACES,
      inputUnit = 'c',
      outputUnit = userDetailsStore.getState().temperature === 'IMPERIAL' ? 'f' : 'c',
    }: TUnitConverterOptionsWithUnits<TAirTemperatureUnit> = {},
  ) => {
    let convertedValue: number | null

    const shouldConvertFromCToF = inputUnit === 'c' && outputUnit === 'f'
    const shouldConvertFromFToC = inputUnit === 'f' && outputUnit === 'c'

    if (isNil(temperature)) {
      convertedValue = null
    } else if (shouldConvertFromCToF) {
      convertedValue = temperature * 1.8 + 32
    } else if (shouldConvertFromFToC) {
      convertedValue = (temperature - 32) / 1.8
    } else {
      // assume there is no conversion required
      convertedValue = Number(temperature)
    }

    const unitSymbol =
      outputUnit === 'c'
        ? translate.measurements.degreesCelsius.unit()
        : translate.measurements.degreesFahrenheit.unit()

    return {
      categoryTitle: () =>
        translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Temperature'),
          unitSymbol,
        }),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Temperature'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
      suffix: () => unitSymbol,
      shortTitle: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.banyanApp('Above Canopy')
        }

        if (canopy === 'IN') {
          return translate.phrases.banyanApp('In Canopy')
        }

        if (canopy === 'IN_REGION') {
          return translate.phrases.placeholder('In Canopy Regional')
        }

        return translate.phrases.banyanApp('Below Canopy')
      },
      title: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Temperature'),
            labelB: translate.phrases.banyanApp('Above Canopy'),
            unitSymbol,
          })
        }

        if (canopy === 'IN') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Temperature'),
            labelB: translate.phrases.banyanApp('In Canopy'),
            unitSymbol,
          })
        }

        if (canopy === 'IN_REGION') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Temperature'),
            labelB: translate.phrases.placeholder('In Canopy Regional'),
            unitSymbol,
          })
        }

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
          labelA: translate.phrases.banyanApp('Temperature'),
          labelB: translate.phrases.banyanApp('Below Canopy'),
          unitSymbol,
        })
      },
      titleWithoutUnit: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Temperature'),
            labelB: translate.phrases.banyanApp('Above Canopy'),
          })
        }

        if (canopy === 'IN') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Temperature'),
            labelB: translate.phrases.banyanApp('In Canopy'),
          })
        }

        if (canopy === 'IN_REGION') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Temperature'),
            labelB: translate.phrases.placeholder('In Canopy Regional'),
          })
        }

        return translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Temperature'),
          labelB: translate.phrases.banyanApp('Below Canopy'),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreesCelsius.value(convertedValue, decimalPlaces)
          : translate.measurements.degreesFahrenheit.value(convertedValue, decimalPlaces)
      },
      valueWithSuffix: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreesCelsius.valueWithUnit(convertedValue, decimalPlaces)
          : translate.measurements.degreesFahrenheit.valueWithUnit(convertedValue, decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

const airTemperatureInversionFunctionMaker =
  () =>
  (
    temperature: number | null = null,
    {
      decimalPlaces = DEFAULT_DECIMAL_PLACES,
      inputUnit = 'c',
      outputUnit = userDetailsStore.getState().temperature === 'IMPERIAL' ? 'f' : 'c',
    }: TUnitConverterOptionsWithUnits<TAirTemperatureUnit> = {},
  ) => {
    let convertedValue: number | null

    const shouldConvertFromCToF = inputUnit === 'c' && outputUnit === 'f'
    const shouldConvertFromFToC = inputUnit === 'f' && outputUnit === 'c'

    if (isNil(temperature)) {
      convertedValue = null
    } else if (shouldConvertFromCToF) {
      convertedValue = temperature * 1.8
    } else if (shouldConvertFromFToC) {
      convertedValue = temperature / 1.8
    } else {
      convertedValue = Number(temperature)
    }

    const unitSymbol =
      outputUnit === 'c'
        ? translate.measurements.degreesCelsius.unit()
        : translate.measurements.degreesFahrenheit.unit()

    return {
      categoryTitle: () =>
        translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Temperature'),
          unitSymbol,
        }),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Temperature'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
      suffix: () => unitSymbol,
      shortTitle: () => {
        return translate.phrases.banyanApp('Inversion')
      },
      title: () => {
        return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Temperature Inversion'),
          unitSymbol,
        })
      },
      titleWithoutUnit: () => {
        return translate.phrases.banyanApp('Temperature Inversion')
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreesCelsius.value(convertedValue, decimalPlaces)
          : translate.measurements.degreesFahrenheit.value(convertedValue, decimalPlaces)
      },
      valueWithSuffix: () => {
        if (outputUnit === 'c')
          return translate.measurements.degreesCelsius.valueWithUnit(convertedValue, decimalPlaces)

        return translate.measurements.degreesFahrenheit.valueWithUnit(convertedValue, decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type TAirTemperatureOptions =
  | 'temperatureAboveCanopy'
  | 'temperatureInCanopy'
  | 'temperatureInCanopyRegional'
  | 'temperatureBelowCanopy'
  | 'temperatureInversion'

export const temperature: Record<
  TAirTemperatureOptions,
  (
    temperature?: number | null,
    options?: TUnitConverterOptionsWithUnits<TAirTemperatureUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  temperatureAboveCanopy: airTemperatureFunctionMaker('ABOVE'),
  temperatureInCanopy: airTemperatureFunctionMaker('IN'),
  temperatureInCanopyRegional: airTemperatureFunctionMaker('IN_REGION'),
  temperatureBelowCanopy: airTemperatureFunctionMaker('BELOW'),
  temperatureInversion: airTemperatureInversionFunctionMaker(),
}
