import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'

export const isNestedLatLngArray = (values: unknown[]): values is number[][] => {
  return !(values.length === 2 && typeof values[0] === 'number' && typeof values[1] === 'number')
}

export const createMultiPolygonBoundsAndLngLats = ({
  coordinatesJSON,
  labelLngLat,
}: {
  coordinatesJSON: string
  labelLngLat: TFieldAssetKeyTypes.TLngLat
}): {
  latLngs: google.maps.LatLng[][]
  bounds: google.maps.LatLngBounds
  latLng: google.maps.LatLng
} => {
  const bounds = new google.maps.LatLngBounds()

  const { coordinates } = JSON.parse(coordinatesJSON) as {
    coordinates: Array<Array<number[] | Array<number[]>>>
  }

  const latLngs: google.maps.LatLng[][] = coordinates[0].reduce((all, values) => {
    if (!isNestedLatLngArray(values)) {
      const [lng, lat] = values

      bounds.extend({ lat, lng })

      if (!all[0]) all[0] = []

      all[0].push(new google.maps.LatLng({ lat, lng }))
    } else {
      const latLngs = values.map(([lng, lat]) => {
        bounds.extend({ lat, lng })

        return new google.maps.LatLng({ lat, lng })
      })

      all.push(latLngs)
    }

    return all
  }, [] as google.maps.LatLng[][])

  const { lng, lat } = lngLatStringToLngLatObject(labelLngLat)

  return { latLngs, bounds, latLng: new google.maps.LatLng({ lng, lat }) }
}
