import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { isNdviValueType } from './_utils/isNdviValueType'
import { parsePoint } from './_utils/parsePoint'

export type OVERLAY_VALUE_META = {
  blockId: TFieldAssetKeyTypes.TBlockId
  blockName: string
  bounds_ne?: string | null
  bounds_sw?: string | null
  bounds: google.maps.LatLngBounds
  image_date?: string
  latLng: google.maps.LatLng
  latLngs: google.maps.LatLng[]
  ndvi_mean_change?: number | null
  ndvi_mean?: number
  previous_image_date?: string
  propertyId: TFieldAssetKeyTypes.TPropertyId
  src?: string | null
  uniformity_change?: number | null
  uniformity?: number
}

// TODO: will eventually come from current values type
export type TImageOverlaysTypesToPropsDictionary = Record<
  string,
  {
    bounds_sw: string
    bounds_ne: string
    src: string
  }
>

// TODO make ComponentProps type not allow "optional" (undefined) props, because there seems to be an issue with React.cloneElement and/or reactRoot.render(reactNode) not picking up the change when a props goes from some value to undefined
export type ReactComponentOverlayView<ComponentProps extends Record<string, unknown>> =
  google.maps.OverlayView & {
    render: (props: ComponentProps) => void
  }

export class ImageOverlaysCache extends MapItemsCache<
  OVERLAY_VALUE_META,
  TImageOverlaysTypesToPropsDictionary,
  {
    groundOverlay: google.maps.GroundOverlay
    clickyPolygon: google.maps.Polygon
  }
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        const groundOverlay = new o.maps.GroundOverlay('', o.meta.bounds)

        const clickyPolygon = new o.maps.Polygon({
          paths: o.meta.latLngs,
          map: o.map,
          visible: true,
          clickable: true,
          fillOpacity: 0.01,
          fillColor: 'grey',
          strokeColor: 'transparent',
          zIndex: 100000,
        })

        return {
          groundOverlay,
          clickyPolygon,
        }
      },
      renderOverlay: (o) => {
        google.maps.event.clearListeners(o.overlay.clickyPolygon, 'click')

        o.overlay.groundOverlay.setMap(null)

        o.overlay.clickyPolygon.setMap(null)

        const { mapVisualValueGroup } = mapControlsStore.getState()
        const valueType = mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType

        if (!valueType || !o.value?.[valueType]) return

        // remove tooltip when rgb
        if (!isNdviValueType(valueType)) {
          mapSelectedCardStore.actions.hide()
        }

        const { bounds_sw, bounds_ne, src } = o.value[valueType]
        const boundsSw = parsePoint(bounds_sw)
        const boundsNe = parsePoint(bounds_ne)

        if (src && boundsSw?.lat && boundsNe?.lat) {
          const boundsFromBQ = new o.maps.LatLngBounds(
            new o.maps.LatLng(boundsSw.lat, boundsSw.lng),
            new o.maps.LatLng(boundsNe.lat, boundsNe.lng),
          )

          o.overlay.groundOverlay?.set('bounds', boundsFromBQ)

          o.overlay.groundOverlay?.set('url', src)

          o.overlay.groundOverlay?.setMap(o.map)
        } else {
          o.overlay.clickyPolygon.set('fillOpacity', 0.7)
        }

        const { metaNdvi } = mapSelectedCardStore.getState()

        // update tooltip when valueType changes
        if (!!metaNdvi && metaNdvi.blockId === o.meta.blockId && isNdviValueType(valueType)) {
          mapSelectedCardStore.actions.showNdvi({
            ...o.meta,
            ...o.value[valueType],
          })
        }

        google.maps.event.addListener(o.overlay.clickyPolygon, 'click', () => {
          setSelectedFieldAsset({ block: o.meta.blockId })

          if (isNdviValueType(valueType)) {
            mapSelectedCardStore.actions.showNdvi({
              ...o.meta,
              ...o.value[valueType],
            })
          }
        })

        o.overlay.clickyPolygon.setMap(o.map)
      },
      hideOverlay: (o) => {
        google.maps.event.clearListeners(o.overlay.clickyPolygon, 'click')

        o.overlay.groundOverlay.setMap(null)

        o.overlay.clickyPolygon.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
