import { Accordion } from '@mantine/core'
import { AlmaChatExample } from './AlmaChatExample'
import { ButtonExamples } from './ButtonExamples/ButtonExamples'
import { CheckboxExamples } from './CheckboxExamples'
import { DevUtils } from './DevUtils/DevUtils'
import { IconsExample } from './IconsExample'
import { InputExamples } from './InputExamples'
import { LayersNavMenu } from './LayersNavMenu'
import { ModalDrawerExample } from './ModalDrawerExamples'
import { RadioExamples } from './RadioExamples'
import { SelectExamples } from './SelectExamples'
import { ToggleButtonExamples } from './ToggleButtonExamples/ToggleButtonExamples'
import { WeatherConditionExamples } from './WeatherConditionExamples'

export const UIExamples = () => {
  /*
   * Component Implementation Examples
   * Add the components here to display in the component accordion
   */
  const components = [
    {
      name: 'buttons',
      title: 'Buttons',
      component: <ButtonExamples />,
    },
    {
      name: 'toggle-button',
      title: 'Toggle Button',
      component: <ToggleButtonExamples />,
    },
    {
      name: 'checkbox',
      title: 'Checkbox',
      component: <CheckboxExamples />,
    },
    {
      name: 'radio',
      title: 'Radio',
      component: <RadioExamples />,
    },
    {
      name: 'input',
      title: 'Input',
      component: <InputExamples />,
    },
    {
      name: 'select',
      title: 'Select',
      component: <SelectExamples />,
    },
    {
      name: 'modalDrawer',
      title: 'Modal Drawer',
      component: <ModalDrawerExample />,
    },
    {
      name: 'navigationMenu',
      title: 'Navigation Menu',
      component: <LayersNavMenu />,
    },
    {
      name: 'alma',
      title: 'Alma',
      component: <AlmaChatExample />,
    },
    {
      name: 'dev-utils',
      title: 'Dev Utils',
      component: <DevUtils />,
    },
    {
      name: 'weather-conditions',
      title: 'Weather Conditions',
      component: <WeatherConditionExamples />,
    },
    {
      name: 'icons',
      title: 'Icons',
      component: <IconsExample />,
    },
  ]

  return (
    <div css={{ padding: 12 }}>
      <Accordion>
        {components.map(({ name, title, component }, index) => {
          return (
            <Accordion.Item value={name} key={index}>
              <Accordion.Control>{title}</Accordion.Control>
              <Accordion.Panel>{component}</Accordion.Panel>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </div>
  )
}
