import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'
import { useCallback, useContext, useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { AlmaContext } from '../AlmaContext/AlmaContext'
import { ThumbDownIcon } from '../ThumbDownIcon/ThumbDownIcon'
import { ThumbUpIcon } from '../ThumbUpIcon/ThumbUpIcon'

type TFeedback = 'like' | 'dislike'

export const AlmaFeedback = () => {
  const [feedbackType, setFeedbackType] = useState<TFeedback | null>(null)
  const { sessionId } = useContext(AlmaContext)

  const logFeedback = useCallback(
    (newFeedbackType: TFeedback | null) => {
      if (!newFeedbackType) return

      if (!sessionId) return

      setFeedbackType(newFeedbackType)

      apiFetch({
        url: '/log-alma-chat-feedback',
        body: {
          sessionId,
          feedbackType: newFeedbackType,
          meta: {
            appName: import.meta.env.VITE_APP_NAME,
            appVersion: APP_PLATFORM_VERSION,
          },
        },
      }).catch((e) => console.error('[Alma Chat] Fail to log feedback', e)) // eslint-disable-line no-console
    },
    [setFeedbackType, sessionId],
  )

  return (
    <div
      css={{
        marginTop: '8px',
        display: 'flex',
        gap: '10px',
      }}
    >
      <ThumbUpIcon onClick={() => logFeedback('like')} isActive={feedbackType === 'like'} />
      <ThumbDownIcon onClick={() => logFeedback('dislike')} isActive={feedbackType === 'dislike'} />
    </div>
  )
}
