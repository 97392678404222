import { Badge, Button, Collapse, Tooltip } from '@mantine/core'
import { getMapOverlaysPadding } from 'App/Map/CurrentValuesMap/_utils/getMapOverlaysPadding'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import type { TPropertyOverviewWithLocation } from 'App/ServiceCenter/types'
import { TNodeStatus } from 'App/ServiceCenter/types'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { IconChevronCircle } from 'components/icons/IconChevronCircle'
import { translate } from 'i18n/i18n'
import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getPropertyBounds } from 'utils/useMapCenteredOnProperties'
import { useScreenSize } from 'utils/useScreenSize'
import { MapContext } from '../../../Map/MapContext/MapContext'
import { getNodeStatusLabel, NODE_STATUS_COLORS } from '../_utils/getActiveNodeStatus'
import { OverlayView } from '../_utils/OverlayView'

const PROPERTY_CARD_DIMENSIONS = {
  wideScreen: {
    maxWidth: 350,
    fontSize: '16px',
    gridTemplateColumns: '150px 150px',
    countFontSize: '30px',
  },
  mobileScreen: {
    maxWidth: 290,
    fontSize: '14px',
    gridTemplateColumns: '120px 120px',
    countFontSize: '20px',
  },
}

const EquipmentCountBadge: React.FC<{ status: TNodeStatus; count: number | null }> = ({ status, count }) => {
  const label =
    status === TNodeStatus.OFFLINE
      ? `${getNodeStatusLabel(TNodeStatus.OFFLINE)} + ${getNodeStatusLabel(TNodeStatus.PENDING)}`
      : getNodeStatusLabel(status)

  return (
    <Tooltip label={label}>
      <span
        style={{
          display: 'flex',
          color: 'black',
          backgroundColor: NODE_STATUS_COLORS[status],
          minWidth: 25,
          padding: '0 5px',
          height: 25,
          fontSize: '14px',
          borderRadius: 12.5,
          margin: '0 4px',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
        }}
      >
        {count && count > 999 ? '999+' : count || 0}
      </span>
    </Tooltip>
  )
}

const EquipmentStatusSummary: React.FC<{ status: TNodeStatus; count: number | null }> = ({
  status,
  count,
}) => {
  const { isWideScreen } = useScreenSize()

  const wrapWithTooltip = (content: React.ReactNode, tooltip?: string | null): React.ReactNode => {
    return tooltip ? <Tooltip label={tooltip}>{content}</Tooltip> : content
  }

  // add tooltip for offline + pending status
  const tooltip =
    status === TNodeStatus.OFFLINE
      ? `${getNodeStatusLabel(TNodeStatus.OFFLINE)} + ${getNodeStatusLabel(TNodeStatus.PENDING)}`
      : null

  return (
    <div>
      <div
        style={{
          color: 'white',
          display: 'inline-block',
          marginRight: 10,
          fontSize: isWideScreen
            ? PROPERTY_CARD_DIMENSIONS.wideScreen.countFontSize
            : PROPERTY_CARD_DIMENSIONS.mobileScreen.countFontSize,
          lineHeight: '30px',
          fontWeight: 500,
        }}
      >
        {count}
      </div>

      {wrapWithTooltip(
        <Badge
          css={{
            color: colors.midnight,
            backgroundColor: NODE_STATUS_COLORS[status],
            fontSize: '12px',
            textTransform: 'none',
            verticalAlign: 'top',
            marginTop: 5,
          }}
        >
          {getNodeStatusLabel(status)}
        </Badge>,
        tooltip,
      )}
    </div>
  )
}

const PropertySummary: React.FC<{
  propertySummaryData: TPropertyOverviewWithLocation
  openedPropertyId: number | null
  setOpenedPropertyId: React.Dispatch<React.SetStateAction<number | null>>
}> = ({ propertySummaryData, openedPropertyId, setOpenedPropertyId }) => {
  const plannedNodesCount = propertySummaryData.pseudonodesCount || 0

  // API-I returns offlineNodesCount as an array of nodeIdentifiers
  const offlineNodesCount = Array.isArray(propertySummaryData.offlineNodesCount)
    ? propertySummaryData.offlineNodesCount.length
    : propertySummaryData.offlineNodesCount || 0

  const onlineNodesCount = propertySummaryData.nodeLogsCount || 0
  const errorNodesCount = propertySummaryData.openServiceIssuesCount || 0
  const { propertyId, propertyShortName } = propertySummaryData
  const opened = propertyId === openedPropertyId
  const { map } = useContext(MapContext)
  const { isWideScreen } = useScreenSize()
  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const property = properties?.[propertyId]
  const blocks = property?.blocks
  const organizationName = property?.organizationName

  if (!property) return null

  const propertyBounds = new google.maps.LatLngBounds()

  if (blocks) {
    // rendering position for property summary center should match with Hub property badge position
    // see /packages/banyan-app/src/App/Map/CurrentValuesMap/caches/PropertyValuesCache/PropertyValuesCache.tsx
    Object.values(blocks).forEach((block) => {
      const { coordinates } = JSON.parse(block.geometry) as { coordinates: Array<Array<number[]>> }

      const blockLatLngs = coordinates[0].map(([lng, lat]) => {
        return new google.maps.LatLng({ lat, lng })
      })

      blockLatLngs.forEach((latLng) => {
        propertyBounds.extend(latLng)
      })
    })
  }

  const propertyCenter =
    blocks && !_.isEmpty(blocks)
      ? {
          lat: propertyBounds.getNorthEast().lat(),
          lng: propertyBounds.getCenter().lng(),
        }
      : {
          lat: property.centroid.coordinates.lat,
          lng: property.centroid.coordinates.lng,
        }

  const handleToggleCardOpened = () => {
    if (opened) {
      setOpenedPropertyId(null)
    } else {
      setOpenedPropertyId(propertyId)
    }
  }

  const handleClickViewProperty = () => {
    const propertyBounds = getPropertyBounds(propertyId)

    if (!_.isEmpty(propertyBounds)) {
      map?.fitBounds(propertyBounds, getMapOverlaysPadding())
    }

    const zoomLevel = map?.getZoom()

    serviceCenterStore.actions.setPropertyOverviewZoomLevel(zoomLevel)

    selectedFieldAssetsStore.setState((s) => ({
      ...s,
      property: propertyId,
    }))
  }

  return (
    <OverlayView
      position={propertyCenter}
      layer={opened ? 'floatPane' : 'overlayMouseTarget'}
      prevent="MAP_HITS_AND_GESTURES"
      containerStyle={{
        zIndex: opened ? 100000 : 999,
        transform: 'translate(-50%, -110%)',
      }}
    >
      <div
        key={propertyId}
        css={{
          backgroundColor: colors.midnight,
          maxWidth: isWideScreen
            ? PROPERTY_CARD_DIMENSIONS.wideScreen.maxWidth
            : PROPERTY_CARD_DIMENSIONS.mobileScreen.maxWidth,
          border: '2px solid white',
          borderRadius: 20,
          padding: '8px 20px',
        }}
        onClick={handleToggleCardOpened}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'white',
            cursor: 'pointer',
            minHeight: 25,
          }}
        >
          <ExpandableChevronIndicator expanded={opened} size={16} />

          <div
            style={{
              marginLeft: 10,
              marginRight: 6,
              fontSize: isWideScreen
                ? PROPERTY_CARD_DIMENSIONS.wideScreen.fontSize
                : PROPERTY_CARD_DIMENSIONS.mobileScreen.fontSize,
              fontWeight: 500,
            }}
          >
            {opened ? `${organizationName} - ${propertyShortName}` : propertyShortName}
          </div>

          {!opened && (
            <>
              <EquipmentCountBadge count={errorNodesCount} status={TNodeStatus.ERROR} />
              <EquipmentCountBadge count={plannedNodesCount} status={TNodeStatus.PLANNED} />
              <EquipmentCountBadge count={offlineNodesCount} status={TNodeStatus.OFFLINE} />
              <EquipmentCountBadge count={onlineNodesCount} status={TNodeStatus.ACTIVE} />
            </>
          )}
        </div>

        <Collapse in={opened}>
          <div
            style={{
              borderTop: '1px solid rgba(255, 255, 255, 0.3)',
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 10,
            }}
          >
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: isWideScreen
                  ? PROPERTY_CARD_DIMENSIONS.wideScreen.gridTemplateColumns
                  : PROPERTY_CARD_DIMENSIONS.mobileScreen.gridTemplateColumns,
                gridColumnGap: 20,
                gridRowGap: 10,
              }}
            >
              <EquipmentStatusSummary count={errorNodesCount} status={TNodeStatus.ERROR} />
              <EquipmentStatusSummary count={plannedNodesCount} status={TNodeStatus.PLANNED} />
              <Tooltip
                label={`${getNodeStatusLabel(TNodeStatus.OFFLINE)} + ${getNodeStatusLabel(
                  TNodeStatus.PENDING,
                )}`}
              >
                <EquipmentStatusSummary count={offlineNodesCount} status={TNodeStatus.OFFLINE} />
              </Tooltip>
              <EquipmentStatusSummary count={onlineNodesCount} status={TNodeStatus.ACTIVE} />
            </div>
          </div>

          <div
            style={{
              borderTop: '1px solid rgba(255, 255, 255, 0.3)',
              paddingTop: 10,
              marginBottom: 10,
            }}
          >
            <Button
              onClick={() => handleClickViewProperty()}
              rightIcon={
                <span style={{ transform: 'rotate(-90deg)' }}>
                  <IconChevronCircle />
                </span>
              }
              variant={'default'}
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                border: '2px solid white',
                width: '100%',
                marginTop: '10px',
              }}
            >
              {translate.phrases.placeholder('View Property')}
            </Button>
          </div>
        </Collapse>
      </div>
    </OverlayView>
  )
}

export const PropertiesOverviewLayer = () => {
  const { map } = useContext(MapContext)
  const propertiesOverview = serviceCenterStore.useSelector((s) => s.propertiesOverview.overviewData)
  const [openedPropertyId, setOpenedPropertyId] = React.useState<number | null>(null)
  const { isWideScreen } = useScreenSize()

  const closeCard = () => {
    setOpenedPropertyId(null)
  }

  useEffect(() => {
    // Click listner to close a opened card when user clicks on the map
    if (!map || !isWideScreen) return

    const clickEventListener = map.addListener('click', closeCard)

    return () => {
      google.maps.event.removeListener(clickEventListener)
    }
  }, [map])

  if (!map) return null

  return (
    <>
      {Object.values(propertiesOverview).map((overview) => (
        <PropertySummary
          openedPropertyId={openedPropertyId}
          setOpenedPropertyId={setOpenedPropertyId}
          key={overview.propertyId}
          propertySummaryData={overview}
        />
      ))}
    </>
  )
}
