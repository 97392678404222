import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'

export function getTrapConsumableLabel(trapConsumable: string): string {
  switch (trapConsumable) {
    case 'lure':
      return translate.phrases.placeholder('Lure')

    case 'liner':
      return translate.phrases.placeholder('Liner')

    case 'coroplast':
      return translate.phrases.placeholder('Coroplast')

    case 'battery':
      return translate.phrases.placeholder('Battery')

    default: {
      Sentry.captureException(new Error(`Trap consumable: "${trapConsumable}" has not been implemented`))

      return trapConsumable
    }
  }
}
