import * as Sentry from '@sentry/react'
// import { useBleManager } from "App/ServiceCenter/BluetoothLowEnergy/BleManager"
// import { ProgramSdiSensor } from "App/ServiceCenter/BluetoothLowEnergy/models/LnrNode/commands"
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useEffect } from 'react'
import type { TActiveNode, TNodeDevicePort } from '../types'
import { createErrorModal } from '../utils/createCommonModals'
import { installOrAttachNodeDevice } from '../utils/installOrAttachNodeDevice'
import { removeNodeDevice } from '../utils/removeNodeDevice'
import { useNavigator } from '../utils/useNavigator'
import { DeviceConfiguration } from './DeviceConfiguration/DeviceConfiguration'
import { DevicesList } from './DevicesList/DevicesList'
// import { SdiDeviceDetection } from "./SdiDeviceDetection/SdiDeviceDetection"
import type { TDeviceConfiguration } from './types'

export enum DevicesManagementPageKey {
  LIST = 'LIST',
  DETECTION = 'DETECTION',
  CONFIGURATION = 'CONFIGURATION',
}

export const DevicesManagement: FC<{
  node: TActiveNode
  isBleRequired: boolean
  onBusy?: (busy: boolean) => void
  onPageChange?: (page: DevicesManagementPageKey) => void
}> = ({ node, isBleRequired, onBusy, onPageChange }) => {
  const {
    page: currentPage,
    extra: pageParams,
    navigateTo,
  } = useNavigator<
    DevicesManagementPageKey,
    {
      port: TNodeDevicePort
      channel: number
      source?: string
    }
  >(DevicesManagementPageKey.LIST)

  // const bleManager = useBleManager()
  const sdiChannels: string[] = node.nodeType === 'rpt' ? [] : ['0', '1', '2', '3']
  const stereoChannels: string[] = node.nodeType === 'rpt' ? [] : ['0', '1', '2']
  const rj11Channels: string[] = ['0']

  const handleRemoveDevice = async (port: TNodeDevicePort, channel: number) => {
    const nodeIdentifier = node.nodeIdentifier

    try {
      if (onBusy) onBusy(true)

      // TODO: Uncomment this when BLE feature is ready
      // if (isBleRequired) {
      //   if (port === 'sdi') {
      //     const req = new ProgramSdiSensor()
      //
      //     req.address = channel.toString()
      //
      //     req.isAdd = false
      //
      //     await bleManager.connectedDevice?.write(req)
      //   }
      // }

      await removeNodeDevice({ nodeIdentifier, device: { connector: port, channel: channel } })
    } catch (e) {
      AboveAllModal.open(
        createErrorModal(translate.phrases.placeholder('Unable to remove device'), (e as Error).message),
      )

      Sentry.captureException(e)
    } finally {
      if (onBusy) onBusy(false)
    }
  }

  const handleConfigureDevice = async (config: TDeviceConfiguration) => {
    const nodeIdentifier = node.nodeIdentifier

    try {
      if (onBusy) onBusy(true)

      // TODO: Uncomment this when BLE feature is ready
      // if (isBleRequired) {
      //   if (config.connector === 'sdi') {
      //     const req = new ProgramSdiSensor()
      //
      //     req.address = config.channel.toString()
      //
      //     req.isAdd = true
      //
      //     await bleManager.connectedDevice?.write(req)
      //   }
      // }

      await installOrAttachNodeDevice(nodeIdentifier, config)

      navigateTo(DevicesManagementPageKey.LIST)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)

      AboveAllModal.open(
        createErrorModal(translate.phrases.placeholder('Unable to configure device'), (e as Error).message),
      )

      Sentry.captureException(e)

      throw e
    } finally {
      if (onBusy) onBusy(false)
    }
  }

  useEffect(() => {
    if (onPageChange) {
      onPageChange(currentPage)
    }
  }, [currentPage, onPageChange])

  return (
    <>
      <div>
        {currentPage === DevicesManagementPageKey.LIST && (
          <DevicesList
            isBleRequired={isBleRequired}
            node={node}
            sdiChannels={sdiChannels}
            stereoChannels={stereoChannels}
            rj11Channels={rj11Channels}
            onAddDevice={(port, channel) => {
              // TODO: Uncomment this when BLE feature is ready
              // if (port === 'sdi' && isBleRequired) {
              //   navigateTo(DevicesManagementPageKey.DETECTION, { port, channel })
              // } else {
              navigateTo(DevicesManagementPageKey.CONFIGURATION, { port, channel })
              // }
            }}
            onRemoveDevice={(port, channel) => handleRemoveDevice(port, channel)}
          />
        )}
        {/* TODO: Uncomment this when BLE feature is ready */}
        {/*{currentPage === DevicesManagementPageKey.DETECTION && pageParams && (*/}
        {/*  <SdiDeviceDetection*/}
        {/*    node={node}*/}
        {/*    channel={pageParams.channel}*/}
        {/*    onConfirm={(source) => {*/}
        {/*      navigateTo(DevicesManagementPageKey.CONFIGURATION, {*/}
        {/*        port: pageParams.port,*/}
        {/*        channel: pageParams.channel,*/}
        {/*        source,*/}
        {/*      })*/}
        {/*    }}*/}
        {/*    onCancel={() => navigateTo(DevicesManagementPageKey.LIST)}*/}
        {/*  />*/}
        {/*)}*/}
        {currentPage === DevicesManagementPageKey.CONFIGURATION && pageParams && (
          <DeviceConfiguration
            node={node}
            port={pageParams.port}
            channel={pageParams.channel}
            detectSource={pageParams.source}
            onConfirm={handleConfigureDevice}
            onCancel={() => navigateTo(DevicesManagementPageKey.LIST)}
          />
        )}
      </div>
    </>
  )
}
