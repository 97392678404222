import { useContext, useEffect } from 'react'
import { smallStore } from 'stores/smallStore'
import { AlmaContext } from '../AlmaContext/AlmaContext'
import { AlmaSession } from '../AlmaSession/AlmaSession'
import { LayerSelection } from '../LayerSelection/LayerSelection'

export const ChatLog = () => {
  const showAlmaChat = smallStore.useSelector((s) => s.showAlmaChat)
  const { chatLog, setChatLog, scrollToBottomOfChat } = useContext(AlmaContext)

  useEffect(() => {
    if (showAlmaChat) {
      setChatLog?.((log) => [
        ...log,
        <AlmaSession />, // eslint-disable-line react/jsx-key
        <LayerSelection />, // eslint-disable-line react/jsx-key
      ])

      scrollToBottomOfChat?.()
    }
  }, [showAlmaChat, setChatLog])

  return <>{...chatLog || []}</>
}
