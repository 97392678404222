export const EllipsisIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.5" cy="9.5" r="2.5" transform="rotate(-90 2.5 9.5)" fill="white">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
      </circle>
      <circle cx="9.5" cy="9.5" r="2.5" transform="rotate(-90 9.5 9.5)" fill="white">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
      </circle>
      <circle cx="16.5" cy="9.5" r="2.5" transform="rotate(-90 16.5 9.5)" fill="white">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
      </circle>
    </svg>
  )
}
