import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const pickProperty = (hasOnlyOneProperty: boolean, properties: Record<number, TProperty>) => {
  const propertyArray = Object.values(properties)

  // use the only available property
  if (hasOnlyOneProperty) {
    return propertyArray[0]
  }

  // use most recently selected property in map
  const selectedPropertyId = selectedFieldAssetsStore.getState().property

  if (selectedPropertyId && selectedPropertyId in properties) {
    return properties[selectedPropertyId]
  }

  // use the first property by alphabetical order
  const firstAlphaProperty = propertyArray.reduce((memo, property) => {
    if (property.propertyName < memo.propertyName) memo = property

    return memo
  }, propertyArray[0])

  if (firstAlphaProperty) {
    return firstAlphaProperty
  }

  // no properties available
  return null
}
