import type { CSSObject } from '@emotion/react'
import type { FC, ReactNode } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { smallStore } from 'stores/smallStore'

const { SPACING_WHEN_MENU_COLLAPSED, SPACING_WHEN_MENU_OPEN } =
  SharedSettings.SPACING_FOR_THINGS_THAT_FLOAT_OVER_LEFT_SIDE_OF_MAP

export const WideScreenSearch: FC<{ children: ReactNode }> = (props) => {
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)
  const isPropertyPlaybackActive = smallStore.useSelector((s) => s.showPropertyPlayback)
  const leftSpacing = menuCollapsed ? SPACING_WHEN_MENU_COLLAPSED : SPACING_WHEN_MENU_OPEN
  const topSpacing = menuCollapsed ? 77 : 5

  const wideScreenContainerStyles: CSSObject = {
    position: 'fixed',
    margin:
      'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    top: isPropertyPlaybackActive ? 77 : topSpacing,
    ...(isPropertyPlaybackActive ? { right: 300 } : { left: leftSpacing }),
  }

  return <div css={wideScreenContainerStyles}>{props.children}</div>
}
