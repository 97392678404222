import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { translate } from 'i18n/i18n'
import { AlmaFeedback } from '../AlmaFeedback/AlmaFeedback'
import { AlmaIcon } from '../AlmaIcon/AlmaIcon'
import { AlmaMessage } from '../AlmaMessage/AlmaMessage'
import { useLlm } from './utils/useLlm'

export const CannedResponse = ({ selectedProperty }: { selectedProperty: TProperty }) => {
  const { isLoading, confirmationMessage, navigate } = useLlm(selectedProperty)

  if (isLoading) return <AlmaMessage showAvatar showEllipsis />

  return (
    <div>
      <div
        css={{
          display: 'flex',
          marginRight: '10%',
        }}
      >
        <div
          css={{
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(0, 0, 0, 1)',
            padding: '10px',
            borderRadius: '0 15px 15px 15px',
            position: 'relative',
          }}
        >
          <div
            css={{
              position: 'absolute',
              left: '-28px',
              top: '0',
            }}
          >
            <AlmaIcon />
          </div>

          {confirmationMessage}

          <div>
            <a css={{ color: 'rgba(40, 112, 214, 1)' }} href="#" onClick={navigate}>
              {translate.phrases.banyanApp('View Details')}
            </a>
          </div>
        </div>
      </div>

      <AlmaFeedback />
    </div>
  )
}
