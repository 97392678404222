import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { mergeApiArgs } from 'App/Map/PanelDetails/_utils/mergeApiArgs'
import type { StackedChartPestSection } from 'components/StackedChart/types'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import * as insectTrapCatchesBlockLevel from './by-scope/insectTrapCatchesBlockLevel'
import * as insectTrapCatchesPropertyLevel from './by-scope/insectTrapCatchesPropertyLevel'
import * as insectTrapCatchesRegionLevel from './by-scope/insectTrapCatchesRegionLevel'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const propertyLevelValues = insectTrapCatchesPropertyLevel.apiArgs({
    selectedValueGroups,
    selectedFieldAssets,
  })

  const blockLevelValues = insectTrapCatchesBlockLevel.apiArgs({ selectedValueGroups, selectedFieldAssets })
  const regionLevelValues = insectTrapCatchesRegionLevel.apiArgs({ selectedValueGroups, selectedFieldAssets })

  return mergeApiArgs(propertyLevelValues, blockLevelValues, regionLevelValues)
}

export const content = ({
  data,
  selectedFieldAssets,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartPestSection[] => {
  const selectedRegion = selectedFieldAssets.region
  const compareRegionsData = data?.regions?.[String(selectedRegion)]
  const regionName = fieldAssetStore.getState()?.regions?.[String(selectedRegion)]?.name ?? null
  const showRegionalData = isRegionDataEnabledForCharts()

  // TODO: this is causing an occasional error
  //Warning: Cannot update a component (`SummaryGridTable`) while rendering a different component
  //(`StackemCharts`).
  detailsPanelStore.setState((prev) => ({
    ...prev,
    hasExtraRightPadding: !isUserOnlyAFreeRegionalUser() && showRegionalData,
  }))

  if (showRegionalData && selectedRegion && isUserOnlyAFreeRegionalUser()) {
    const regionLevelData = insectTrapCatchesRegionLevel.content({
      data,
      selectedValueGroups,
      selectedFieldAssets,
      regionId: selectedRegion,
      compareRegionsData,
      regionName,
    })

    return regionLevelData
  } else {
    const propertyLevelContent = insectTrapCatchesPropertyLevel.content({
      data,
      selectedValueGroups,
      selectedFieldAssets,
      compareSeasonsData,
      compareRegionsData,
      regionName,
    })

    const blockLevelContent = insectTrapCatchesBlockLevel.content({
      data,
      selectedValueGroups,
      selectedFieldAssets,
      compareSeasonsData,
      compareRegionsData,
      regionName,
    })

    return [...propertyLevelContent, ...blockLevelContent]
  }
}
