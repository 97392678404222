import { detailsPanelStore } from 'stores/detailsPanelStore'
import { smallStore } from 'stores/smallStore'

export const useIsRegionDataEnabled = () => {
  const isRegionDataEnabled = smallStore.useSelector((s) => {
    return s.showRegionalData
  })

  return isRegionDataEnabled
}

export const isRegionDataEnabled = () => {
  const isRegionDataEnabled = smallStore.getState().showRegionalData

  return isRegionDataEnabled
}

export const useIsRegionDataEnabledForCharts = () => {
  const isRegionDataEnabled = smallStore.useSelector((s) => {
    return s.showRegionalData
  })

  const isCompareSeasonsActive = detailsPanelStore.useSelector((s) => Boolean(s.compareSeasonsInterval))

  return isRegionDataEnabled && !isCompareSeasonsActive
}

export const isRegionDataEnabledForCharts = () => {
  const isRegionDataEnabled = smallStore.getState().showRegionalData
  const isCompareSeasonsActive = detailsPanelStore.getState().compareSeasonsInterval

  return isRegionDataEnabled && !isCompareSeasonsActive
}
