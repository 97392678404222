import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import type { TNodeConsumablesStatusGetParams } from '../../types'

export const serviceCenterNodeConsumablesStatusGet = async ({
  propertyId,
  nodeIdentifier,
  lastService,
}: TNodeConsumablesStatusGetParams) => {
  const nodeConsumablesStatus = await apiFetch<
    routes.ServiceCenterNodeConsumablesStatusGet.Request,
    routes.ServiceCenterNodeConsumablesStatusGet.Response
  >({
    url: routes.ServiceCenterNodeConsumablesStatusGet.path,
    body: {
      propertyId,
      nodeIdentifier,
      lastService,
    },
    params: {
      method: 'POST',
    },
  })

  return nodeConsumablesStatus
}
