import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { EAggregationInterval } from 'App/Map/types'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'
import { useApiREST } from 'utils/useApiREST'
import type { TTimeseries } from '../TrapBadgeComponent'

export const useFetchFullTimeSeriesAndAddToState = ({
  insectId,
  lngLat,
  preventFetch,
  setTimeseriesTrapCatchDataObject,
  setSelectedDate,
  timezone,
}: {
  insectId?: TFieldAssetKeyTypes.TInsectId
  lngLat: TFieldAssetKeyTypes.TLngLat
  preventFetch: boolean
  setTimeseriesTrapCatchDataObject: Dispatch<SetStateAction<Record<string, TTimeseries>>>
  setSelectedDate: Dispatch<SetStateAction<string>>
  timezone: string
}) => {
  const timeseriesValueType =
    `insectTrapCatches_${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['points']

  const { loading: timeseriesTrapCatchLoading } = useApiREST<
    routes.Values.Request,
    routes.Values.Response,
    null
  >({
    url: routes.Values.path,
    preventFetch,
    body: {
      dateFrom: getIsoWithoutTheZ(
        moment.tz(SharedSettings.FIRST_DAY_OF_DATA_FOR_MANY_THINGS, timezone).startOf('day'),
      ),
      dateTo: getIsoWithoutTheZ(moment.tz(timezone).endOf('day')),
      points: {
        lngLats: [lngLat],
        valuesRequested: {
          [timeseriesValueType]: {
            preferredAggregationInterval: EAggregationInterval.DAILY,
          },
        },
      },
    },
    watchers: [preventFetch],
    shaper: (data) => {
      const dataToIterate: TTimeseries[] =
        data?.points?.[lngLat]?.values?.[timeseriesValueType]?.[0]?.timeseries ?? []

      const newTimeseriesTrapCatchDataToAdd: Record<string, TTimeseries> = {}

      dataToIterate.forEach((d, i) => {
        const formattedTimeStamp = moment.tz(d.timestamp, timezone).startOf('day').toISOString()

        newTimeseriesTrapCatchDataToAdd[d.timestamp] = { ...d, timestamp: formattedTimeStamp }

        if (i === dataToIterate.length - 1) {
          setSelectedDate((prevSelectedDate) => {
            if (!!prevSelectedDate || !d.imageHash) return prevSelectedDate

            return formattedTimeStamp
          })
        }
      })

      setTimeseriesTrapCatchDataObject((prevState) => ({ ...prevState, ...newTimeseriesTrapCatchDataToAdd }))

      return null
    },
  })

  return { timeseriesTrapCatchLoading }
}
