import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { checkAuthorization } from 'utils/checkAuthorization'

export const selectedRegionHasPermission = ({
  permission,
  regionId = selectedFieldAssetsStore.getState().region,
}: {
  permission: TPermission
  regionId?: string | null
}) => {
  if (!regionId) return false

  return checkAuthorization({ permission, entity: regionId })
}
