import type { TabPane } from 'components/DetailPanel/DetailPanel'
import { DetailPanel } from 'components/DetailPanel/DetailPanel'
import { IconDetails } from 'components/icons/IconDetails'
import { IconSummary } from 'components/icons/IconSummary'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { usePreventPanelDetailsFromBeingOpen } from 'utils/usePreventPanelDetailsFromBeingOpen'
import { PanelDetails } from './PanelDetails/PanelDetails'

export const MAP_DETAILS_PANEL_WRAPPER_ID = 'map-details-panel'

export const MapDetailsPanel = () => {
  const tabs: TabPane[] = [
    {
      key: 'SUMMARY',
      label: translate.phrases.banyanApp('Summary'),
      icon: (
        <span css={{ fontSize: 32 }}>
          <IconSummary />
        </span>
      ),
    },
    {
      key: 'DETAILS',
      label: translate.phrases.banyanApp('Details'),
      icon: (
        <span css={{ fontSize: 30 }}>
          <IconDetails />
        </span>
      ),
    },
  ]

  const preventPanelDetailsFromBeingOpen = usePreventPanelDetailsFromBeingOpen()
  const isForcedOpen = detailsPanelStore.useSelector((s) => s.isForcedOpen)

  return preventPanelDetailsFromBeingOpen ? null : (
    <DetailPanel wrapperId={MAP_DETAILS_PANEL_WRAPPER_ID} tabs={tabs}>
      {({ width, height, isPanelExpanded }) => {
        return (
          <PanelDetails
            panelWidth={width}
            panelHeight={height}
            isPanelExpanded={isPanelExpanded || isForcedOpen}
          />
        )
      }}
    </DetailPanel>
  )
}
