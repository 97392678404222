export const CustomerMessage = ({ textContent }: { textContent: string }) => {
  return (
    <div
      css={{
        display: 'flex',
        marginLeft: '10%',
        justifyContent: 'flex-end',
      }}
    >
      <div
        css={{
          backgroundColor: 'rgba(248, 248, 248, 1)',
          padding: '10px',
          borderRadius: '15px 0 15px 15px',
          border: '1px rgba(226, 226, 226, 1) solid',
        }}
      >
        {textContent}
      </div>
    </div>
  )
}
