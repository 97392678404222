import type {
  TInsect,
  TProperty,
} from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup/fieldAssetValueTypes'
import type { TNavigationKey } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'
import { generateFlatNavigationList } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'

export const almaRequestBodyData = {
  availableViews() {
    const flatNavigationList = generateFlatNavigationList()

    let result: Array<string> = []

    for (const key in flatNavigationList) {
      const childLayers = flatNavigationList[key as TNavigationKey]?.childLayers

      if (childLayers) {
        result.push(...Object.keys(childLayers))
      } else {
        result.push(key)
      }
    }

    return result
  },

  availableProperties(properties: Record<number, TProperty>) {
    return Object.values(properties).reduce((memo, property) => {
      memo[property.propertyName] = property.propertyId

      return memo
    }, {} as Record<string, number>)
  },

  availablePests(insects: Record<number, TInsect>) {
    return Object.values(insects).reduce((memo, insect) => {
      memo[insect.acronym.toLocaleLowerCase()] = [insect.insectId, insect.name.toLocaleLowerCase()]

      return memo
    }, {} as Record<string, [number, string]>)
  },
}
