import { Button } from 'components/Button/Button'
import { IconPencil } from 'components/icons/IconPencil'

export const EditButton = ({ onClick, label = 'Edit' }: { onClick: () => void; label?: string }) => {
  return (
    <Button onClick={onClick} leftIcon={<IconPencil />} variant="link">
      {label}
    </Button>
  )
}
