import { NDVI } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/NDVI'
import { Trap } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/Trap'
import { MapCard } from 'components/MapCard/MapCard'
import { useCallback } from 'react'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'

const ENABLE_WIP_CARDS = false

export const SelectedMapCard: React.FC = () => {
  const mapSelectedCardStoreState = mapSelectedCardStore.useSelector((s) => s)
  const visible = Object.keys(mapSelectedCardStoreState).length > 0
  const onClose = useCallback(() => mapSelectedCardStore.actions.hide(), [])

  if (!visible) return null

  if (ENABLE_WIP_CARDS && !HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD && mapSelectedCardStoreState.metaBlock) {
    return (
      <MapCard
        latLng={mapSelectedCardStoreState.metaBlock.latLng.toJSON()}
        title={mapSelectedCardStoreState.metaBlock.blockName}
        onClose={onClose}
        footer={null}
      >
        {null}
      </MapCard>
    )
  }

  if (ENABLE_WIP_CARDS && !HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD && mapSelectedCardStoreState.metaStation) {
    return (
      <MapCard
        latLng={mapSelectedCardStoreState.metaStation.latLng.toJSON()}
        title={mapSelectedCardStoreState.metaStation.name}
        onClose={onClose}
        footer={null}
      >
        {null}
      </MapCard>
    )
  }

  if (!HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD && mapSelectedCardStoreState.metaNdvi) {
    return (
      <NDVI
        {...mapSelectedCardStoreState.metaNdvi}
        key={mapSelectedCardStoreState.metaNdvi.blockId}
        onClose={onClose}
      />
    )
  }

  if (mapSelectedCardStoreState.metaTrap) {
    return (
      <Trap
        {...mapSelectedCardStoreState.metaTrap}
        key={mapSelectedCardStoreState.metaTrap.lngLat}
        onClose={onClose}
      />
    )
  }

  return null
}
