import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartSection, TChartSeries, TValueTypeObject } from 'components/StackedChart/types'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { updateTooltipContents } from 'components/StackedChart/_utils/lineChartTooltipFormatter/updateTooltipContents'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import { default as moment } from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { generateSeriesForEnvironmentalChart } from '../_utils/generateSeriesForEnvironmentalChart/generateSeriesForEnvironmentalChart'
import { getXDateFormat } from '../_utils/getXDateFormat'
import {
  makeRegionalSeries,
  makeRegionalSeriesFromRegularSeries,
} from '../_utils/makeRegionalSeriesFromRegularSeries'
import { plotOptionsToHideInactiveSeriesHarderMaker } from '../_utils/plotOptionsToHideInactiveSeriesHarderMaker'
import { getWindCardinalDirection } from './getWindCardinalDirection'

const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const pointCategory: TPointCategory = 'outOfBlockPoint'

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'windDirection',
  'windSpeed',
]

const getRegionalValuesRequested = ({
  selectedFieldAssets,
  preferredAggregationInterval,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  preferredAggregationInterval: {
    preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily>
  }
}): Partial<routes.Values.Request> => {
  const selectedRegion = selectedFieldAssets.region

  if (!selectedRegion) return {}

  const valuesForRegion = fieldAssetStore.getState().regions?.[selectedRegion]?.valuesTimeseries || []
  const valuesToRequestForRegion = possibleValueTypesToRequest.filter((v) => valuesForRegion.includes(v))

  const valuesRequested = valuesToRequestForRegion.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getPointsValuesRequested = ({
  selectedFieldAssets,
  preferredAggregationInterval,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  preferredAggregationInterval: {
    preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily>
  }
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets[pointCategory] || !selectedFieldAssets.property) return {}

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.wind) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const pointsToRequest = getPointsValuesRequested({
    selectedFieldAssets,
    preferredAggregationInterval,
  })

  const showRegionalData = isRegionDataEnabledForCharts()

  const regionsToRequest = showRegionalData
    ? getRegionalValuesRequested({
        selectedFieldAssets,
        preferredAggregationInterval,
      })
    : {}

  return { ...pointsToRequest, ...regionsToRequest }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.windSpeed().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-wind',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const showRegionalData = isRegionDataEnabledForCharts()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = String(selectedFieldAssets[pointCategory])
  const selectedRegion = selectedFieldAssets.region
  const series: TChartSeries[] = []

  const isDaily =
    data?.points?.[stationLngLat]?.values?.windSpeed?.[0]?.metadata?.aggregationInterval ===
      EAggregationInterval.DAILY ||
    data?.regions?.[String(selectedRegion)]?.values?.windSpeed?.[0]?.metadata?.aggregationInterval ===
      EAggregationInterval.DAILY

  if (doesSelectedPointHaveValueTypes({ valuesTimeseries: possibleValueTypesToRequest, pointCategory })) {
    const pointSeries = generateSeriesForEnvironmentalChart({
      hasSensor: Boolean(selectedFieldAssets[pointCategory]),
      valueTypeObject: data.points?.[stationLngLat]?.values?.windSpeed?.[0] as TValueTypeObject,
      compareSeasonsValueTypeObject: compareSeasonsData.points?.[stationLngLat]?.values
        ?.windSpeed?.[0] as TValueTypeObject,
      unitConverter: unitConverter.windSpeed,
    })

    series.push(...pointSeries)
  }

  if (showRegionalData && selectedRegion) {
    const regionData = data.regions?.[selectedRegion]?.values?.windSpeed?.[0] as TValueTypeObject
    const regionName = fieldAssetStore.getState().regions?.[selectedRegion]?.name

    if (series.length) {
      const baseSeries = series[0]

      const shapedData = (regionData?.timeseries || []).map((ts) => ({
        x: +new Date(ts.timestamp),
        y: unitConverter.windSpeed(typeof ts.value === 'object' ? ts.value?.average : ts.value).value(),
        low: isDaily
          ? unitConverter.windSpeed(typeof ts.value === 'object' ? ts.value?.min : null).value()
          : undefined,
        high: isDaily
          ? unitConverter.windSpeed(typeof ts.value === 'object' ? ts.value?.max : null).value()
          : undefined,
      }))

      series.push(makeRegionalSeriesFromRegularSeries(baseSeries, { name: regionName, data: shapedData }))
    } else {
      const seriesName = selectedFieldAssets.property ? regionName : undefined // TODO: this should really be a permissions check
      // and not show regional data if property doesn't have wind permission

      const regionalSeries =
        showRegionalData && selectedRegion
          ? generateSeriesForEnvironmentalChart({
              hasSensor: true,
              valueTypeObject: data.regions?.[selectedRegion]?.values?.windSpeed?.[0] as TValueTypeObject,
              unitConverter: unitConverter.windSpeed,
              overrideSeriesName: seriesName,
            })
          : []

      regionalSeries.forEach(makeRegionalSeries)

      series.push(...regionalSeries)
    }
  }

  const windDirectionDictionary: Record<number, number | null> = {}
  const windDirectionDictionaryCompareSeasons: Record<number, number | null> = {}

  data.points?.[stationLngLat]?.values?.windDirection?.[0]?.timeseries?.forEach((d) => {
    const epoch = +new Date(d.timestamp)
    const value = d.value === null || typeof d.value === 'number' ? d.value : d.value?.average

    windDirectionDictionary[epoch] = value
  })

  compareSeasonsData.points?.[stationLngLat]?.values?.windDirection?.[0]?.timeseries?.forEach((d) => {
    const epoch = +new Date(d.timestamp)
    const value = d.value === null || typeof d.value === 'number' ? d.value : d.value?.average

    windDirectionDictionaryCompareSeasons[epoch] = value
  })

  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const allForecastMoments = [
    data.points?.[stationLngLat]?.values?.windSpeed?.[0]?.metadata.forecastStartsAt,
    data.regions?.[String(selectedRegion)]?.values?.windSpeed?.[0]?.metadata.forecastStartsAt,
  ]
    .filter(Boolean)
    .map((d) => moment.tz(d, timezone))

  const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          plotOptions: plotOptionsToHideInactiveSeriesHarderMaker(),
          series,
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
            formatter: function (tooltip) {
              let tooltipContents = lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)

              if (isDaily) return tooltipContents

              // TODO: put regional wind direction in tooltip
              tooltipContents = updateTooltipContents({
                seriesId: series[0].id,
                tooltipContents,
                fieldsToChange: {
                  valueSuffix: ({ content }) => {
                    const direction = windDirectionDictionary[Number(this.x)]

                    return isNil(content) || isNil(direction)
                      ? ''
                      : translate.phrases.banyanApp('{{windSpeed}} {{windDirection}}', {
                          windSpeed: content,
                          windDirection: getWindCardinalDirection(direction),
                        })
                  },
                  compareSeasonsValueSuffix: ({ content }) => {
                    const direction = windDirectionDictionaryCompareSeasons[Number(this.x)]

                    return isNil(content) || isNil(direction)
                      ? ''
                      : translate.phrases.banyanApp('{{windSpeed}} {{windDirection}}', {
                          windSpeed: content,
                          windDirection: getWindCardinalDirection(direction),
                        })
                  },
                },
              })

              return tooltipContents
            },
          },
        },
      },
    ],
  }
}
