import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'

const formatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})

export const useDefaultTimeRange = () => {
  const detailsPanelTimeRange = detailsPanelStore.useSelector(({ dateFrom, dateTo }) => ({
    dateFrom,
    dateTo,
  }))

  const now = moment.tz(moment.tz.guess())
  const twoWeeksBefore = getIsoWithoutTheZ(now.subtract(14, 'days').startOf('day'))
  const twoWeeksAfter = getIsoWithoutTheZ(now.add(14, 'days').endOf('day'))

  const dateFrom =
    twoWeeksBefore < detailsPanelTimeRange.dateFrom ? twoWeeksBefore : detailsPanelTimeRange.dateFrom

  const dateTo = twoWeeksAfter < detailsPanelTimeRange.dateTo ? detailsPanelTimeRange.dateTo : twoWeeksAfter
  const dateCurrent = getIsoWithoutTheZ(now)

  return {
    // @ts-expect-error should be fixed by https://github.com/microsoft/TypeScript/pull/47740
    humanText: formatter.formatRange(Date.parse(dateFrom), Date.parse(dateTo)),
    dateFrom,
    dateTo,
    dateCurrent,
  }
}
