import type { VV } from '@semios/app-platform-value-type-definitions'
import moment from 'moment-timezone'

export const findClosestTimeseries = (
  timeseries: {
    timestamp: string
    value: VV.DomainTypes.Weather.TWeatherReturn
    isDeviceReported?: boolean | null
  }[],
  targetTime: Date,
  timezone: string,
) => {
  let left = 0
  let right = timeseries.length - 1

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)
    const midTime = moment.tz(timeseries[mid].timestamp, timezone)
    const targetMoment = moment.tz(targetTime, timezone)

    if (midTime.isSame(targetMoment)) {
      return timeseries[mid]
    } else if (midTime.isBefore(targetMoment)) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }

  // At this point, left > right
  // We need to compare the two closest points
  const leftDiff =
    timeseries[right] && Math.abs(moment.tz(timeseries[right].timestamp, timezone).diff(targetTime))

  const rightDiff =
    timeseries[left] && Math.abs(moment.tz(timeseries[left].timestamp, timezone).diff(targetTime))

  return leftDiff < rightDiff ? timeseries[right] : timeseries[left]
}
