import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { PropertyOrRegionNameHeader } from '../../_utils/PropertyOrRegionNameHeader'
import { DateRangeRow } from '../DataRangeRow/DateRangeRow'
import { DateRangeRowSelfRegisteredUser } from '../DataRangeRow/DateRangeRowSelfRegisteredUser'

export const ChildrenUpper = ({
  containerWidth,
  selectedValueGroups,
}: {
  containerWidth: number
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}) => {
  const isFreeRegionalUser = isUserOnlyAFreeRegionalUser()

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div css={{ textAlign: containerWidth < 500 ? 'center' : 'start' }}>
        <PropertyOrRegionNameHeader />
      </div>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 20,
          justifyContent: containerWidth < 500 ? 'center' : 'flex-start',
        }}
      >
        {!isFreeRegionalUser ? (
          <DateRangeRow selectedValueGroups={selectedValueGroups} />
        ) : (
          <DateRangeRowSelfRegisteredUser selectedValueGroups={selectedValueGroups} />
        )}
      </div>
    </div>
  )
}
