import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import type { TInsect } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup/fieldAssetValueTypes'
import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import type { TNavigationKey } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'
import type { Dispatch, SetStateAction } from 'react'
import { createContext, useMemo, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TLayer } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { useDefaultTimeRange } from './utils/useDefaultTimeRange'

export type TSelectedLayer =
  | { key: TLayer; translatedTitle: string }
  | { key: TNavigationKey; translatedTitle: string }
  | null

export const AlmaContext = createContext<{
  selectedLayer: TSelectedLayer
  sessionId: string | null
  properties: Record<number, TProperty>
  insects: Record<number, TInsect>
  setSelectedLayer?: Dispatch<SetStateAction<TSelectedLayer>>
  setSessionId?: Dispatch<SetStateAction<string | null>>
  scrollToBottomOfChat?: () => void
  chatLog?: Array<EmotionJSX.Element>
  setChatLog?: Dispatch<SetStateAction<EmotionJSX.Element[]>>
  hasOnlyOneProperty: boolean
  defaultTimeRange: {
    humanText: string
    dateFrom: string
    dateTo: string
    dateCurrent: string
  }
}>({
  selectedLayer: null,
  sessionId: null,
  properties: {},
  insects: {},
  hasOnlyOneProperty: false,
  defaultTimeRange: {
    humanText: '',
    dateFrom: '',
    dateTo: '',
    dateCurrent: '',
  },
})

export const AlmaContextProvider = ({
  children,
  scrollToBottomOfChat,
}: {
  children: EmotionJSX.Element
  scrollToBottomOfChat: () => void
}) => {
  const defaultTimeRange = useDefaultTimeRange()
  const properties = fieldAssetStore.useSelector((s) => s.properties ?? {})
  const insects = fieldAssetStore.useSelector((s) => s.insects ?? {})
  const hasOnlyOneProperty = useMemo(() => Object.values(properties).length === 1, [properties])
  const [selectedLayer, setSelectedLayer] = useState<TSelectedLayer>(null)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [chatLog, setChatLog] = useState<EmotionJSX.Element[]>([])

  return (
    <AlmaContext.Provider
      value={{
        selectedLayer,
        setSelectedLayer,
        sessionId,
        setSessionId,
        scrollToBottomOfChat,
        properties,
        insects,
        chatLog,
        setChatLog,
        hasOnlyOneProperty,
        defaultTimeRange,
      }}
    >
      {children}
    </AlmaContext.Provider>
  )
}
