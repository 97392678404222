import { Group, Input, LoadingOverlay, Radio as R } from '@mantine/core'
import { useForm } from '@mantine/form'
import { closeModal, openModal } from '@mantine/modals'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import { openTrapConfigurationModal } from 'App/ServiceCenter/NodeInstallation/TrapConfiguration/TrapConfiguration'
import { LuresMultiSelect } from 'App/ServiceCenter/Shared/LuresMultiSelect'
import { nodeConsumableActions } from 'App/ServiceCenter/utils/constants/nodeConsumables'
import { EditButton } from 'components/EditButton'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { Radio } from 'components/Radio/Radio'
import { translate } from 'i18n/i18n'
import { isEmpty, upperFirst } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { showNotification } from 'utils/showNotification'
import { openActionMenu } from '../ActionMenu/ActionMenu'
import { Footer } from '../HelpGuideModal/Footer/Footer'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveNode } from '../types'
import { ConsumableType } from '../types'
import { serviceCenterNodeConsumablesStatusGet } from '../utils/api/serviceCenterNodeConsumablesStatusGet'
import { getNodeActions } from '../utils/getNodeActions'
import { getTrapConsumableLabel } from '../utils/getTrapConsumableLabel'
import { updateNodeConfig } from '../utils/updateNodeConfig'

const MODAL_ID = 'node-consumable'

type TNodeConsumablesStatus = {
  isLureInstalled: boolean
  lureIdsActive?: number[]
  isLinerInstalled: boolean
  isCoroplastInstalled: boolean
}

type FormValues = {
  lureAction: keyof typeof nodeConsumableActions | null
  linerAction: keyof typeof nodeConsumableActions | null
  coroplastAction: keyof typeof nodeConsumableActions | null
  batteryAction: keyof typeof nodeConsumableActions | null
  lureIdsActive: number[]
}

const ClearButton = ({ onClick }: { onClick: () => void }) => (
  <div
    css={{
      textDecoration: 'underline',
      padding: '0 5px',
      marginLeft: 'auto',
      fontSize: 14,
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    {translate.phrases.banyanApp('Clear')}
  </div>
)

export const openNodeConsumableModal = () => {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <NodeConsumableModal />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const NodeConsumableModal: React.FC = () => {
  // Use the useSelector hook
  const lures = serviceCenterStore.useSelector(serviceCenterStore.selectors.getLuresAsArray)
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)
  const insectsById = fieldAssetStore.useSelector((s) => s.insects) || {}
  // Use the useState hook
  const [isLoading, setIsLoading] = useState(false)
  // Declare the variables
  const trapConsumables = ['liner', 'coroplast']

  const {
    propertyId,
    nodeType,
    nodeIdentifier,
    name: nodeName,
    trap: { targetInsectId } = {},
  } = selectedNode as TActiveNode

  const [nodeConsumablesStatus, setNodeConsumablesStatus] = useState<TNodeConsumablesStatus>({
    isLureInstalled: false,
    lureIdsActive: [],
    isLinerInstalled: false,
    isCoroplastInstalled: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const currentNodeConsumablesStatus = await serviceCenterNodeConsumablesStatusGet({
          propertyId,
          nodeIdentifier,
        })

        const isLinerInstalled = !!currentNodeConsumablesStatus?.liner?.activeStart
        const isCoroplastInstalled = !!currentNodeConsumablesStatus?.coroplast?.activeStart
        const isLureInstalled = !isEmpty(currentNodeConsumablesStatus?.lures?.active)

        const lureIdsActive = isLureInstalled
          ? Object.keys(currentNodeConsumablesStatus.lures.active).map((lureId) => +lureId)
          : []

        setNodeConsumablesStatus({
          isLureInstalled,
          lureIdsActive,
          isLinerInstalled,
          isCoroplastInstalled,
        })

        form.setFieldValue('lureIdsActive', lureIdsActive)
      } catch (error) {
        Sentry.captureException(error)

        showNotification({
          type: 'error',
          message: translate.phrases.placeholder('Failed to load trap consumables.'),
        })
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [selectedNode])

  const initialValues = useMemo(
    () => ({
      lureAction: null,
      linerAction: null,
      coroplastAction: null,
      batteryAction: null,
      lureIdsActive: nodeConsumablesStatus['lureIdsActive'] || [],
    }),
    [nodeConsumablesStatus],
  )

  const form = useForm<FormValues>({
    initialValues,
    validate: {
      lureIdsActive: () => {
        const {
          values: { lureAction, lureIdsActive },
        } = form

        if (
          lureAction &&
          [nodeConsumableActions.INSTALL, nodeConsumableActions.SWAP].includes(lureAction) &&
          isEmpty(lureIdsActive)
        ) {
          return translate.phrases.placeholder('Select lure type(s)')
        }
      },
    },
  })

  const hasFormChanged = form.isDirty()

  const handleBack = () => {
    const actions = getNodeActions(selectedNode as TActiveNode)

    // Close the node consumable modal
    closeModal(MODAL_ID)

    // Open the action menu
    openActionMenu(actions)
  }

  const handleSubmit = async () => {
    const { hasErrors } = form.validate()

    if (hasErrors) return

    setIsLoading(true)

    try {
      const {
        values: { lureAction, linerAction, coroplastAction, batteryAction, lureIdsActive },
      } = form

      const consumablesToUpdate: (keyof typeof ConsumableType)[] = []
      const consumablesToRemove: (keyof typeof ConsumableType)[] = []

      const actionMapping: Record<string, { action: string | null; consumableType: string }> = {
        lure: { action: lureAction, consumableType: ConsumableType.LURE },
        liner: { action: linerAction, consumableType: ConsumableType.LINER },
        coroplast: { action: coroplastAction, consumableType: ConsumableType.COROPLAST },
        battery: { action: batteryAction, consumableType: ConsumableType.BATTERY },
      }

      Object.values(actionMapping).forEach(({ action, consumableType }) => {
        if (action === nodeConsumableActions.INSTALL || action === nodeConsumableActions.SWAP) {
          consumablesToUpdate.push(consumableType as keyof typeof ConsumableType)
        } else if (action === nodeConsumableActions.REMOVE) {
          consumablesToRemove.push(consumableType as keyof typeof ConsumableType)
        }
      })

      const promises = []

      if (consumablesToUpdate.length) {
        promises.push(
          updateNodeConfig({
            propertyId,
            nodeType,
            nodeIdentifiers: [nodeIdentifier],
            trap: {
              targetInsectId,
              consumableTypes: consumablesToUpdate,
              consumableOperationType: 'SET',
              lureIds: lureIdsActive,
            },
          }),
        )
      }

      if (consumablesToRemove.length) {
        promises.push(
          updateNodeConfig({
            nodeIdentifiers: [nodeIdentifier],
            propertyId,
            nodeType,
            trap: {
              targetInsectId,
              consumableTypes: consumablesToRemove,
              consumableOperationType: 'REMOVE',
            },
          }),
        )
      }

      await Promise.all(promises)

      closeModal(MODAL_ID)
    } catch (error) {
      Sentry.captureException(error)

      showNotification({
        type: 'error',
        message: translate.phrases.placeholder('Failed to update trap consumables.'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnValuesChange = (values: FormValues) => {
    const { lureAction } = values

    if (lureAction && [nodeConsumableActions.INSTALL, nodeConsumableActions.SWAP].includes(lureAction)) {
      // if there only one lure type for the target insect, select it by default

      if (targetInsectId) {
        const filteredLureIds = lures
          .filter((lure) => lure.insectIds.includes(targetInsectId))
          .map(({ lureId }) => lureId)

        if (filteredLureIds.length === 1) values.lureIdsActive = filteredLureIds
      }

      form.validate()
    }
  }

  const handleOnClearSelection = (resetValues: Partial<FormValues>) => {
    const resetFormValues = {
      ...form.values,
      ...resetValues,
    }

    form.setValues(resetFormValues)

    handleOnValuesChange(resetFormValues)
  }

  const isLureTypesVisible = () => {
    return (
      nodeConsumablesStatus.isLureInstalled ||
      (form.values.lureAction === nodeConsumableActions.INSTALL &&
        form.values.lureAction !== initialValues.lureAction)
    )
  }

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <WideHeader
        title={translate.phrases.placeholder('Manage Trap Consumables')}
        onClose={handleBack}
        isSecondaryModal={true}
      />

      <div
        css={{
          borderRadius: 3,
          padding: 20,
        }}
      >
        <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 css={{ margin: 0, display: 'flex', alignItems: 'center' }}>{nodeName}</h4>
          <EditButton
            label={translate.phrases.placeholder('Edit Trap')}
            onClick={() => openTrapConfigurationModal()}
          />
        </div>

        {targetInsectId && <p css={{ margin: 0, fontSize: '14px' }}>{insectsById[targetInsectId].name}</p>}

        <Input.Wrapper id="lureAction" css={{ marginTop: 20 }} label={getTrapConsumableLabel('lure')}>
          <R.Group {...form.getInputProps('lureAction')}>
            <Group mt="xs" position="apart" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', gap: 10 }}>
                {nodeConsumablesStatus.isLureInstalled ? (
                  <>
                    <Radio value={nodeConsumableActions.SWAP} label={translate.phrases.placeholder('Swap')} />
                    <Radio
                      value={nodeConsumableActions.REMOVE}
                      label={translate.phrases.placeholder('Remove')}
                    />
                  </>
                ) : (
                  <>
                    <Radio
                      value={nodeConsumableActions.INSTALL}
                      label={translate.phrases.placeholder('Install')}
                    />
                  </>
                )}
              </div>

              {form.isDirty('lureAction') && (
                <ClearButton
                  onClick={() =>
                    handleOnClearSelection({
                      lureAction: initialValues.lureAction,
                      lureIdsActive: initialValues.lureIdsActive,
                    })
                  }
                />
              )}
            </Group>
          </R.Group>
        </Input.Wrapper>

        {/* Lure input */}
        <Input.Wrapper
          id="lureIdsActive"
          css={{
            marginTop: 10,
            display: isLureTypesVisible() ? 'block' : 'none',
          }}
          error={form.errors.lureIdsActive}
        >
          <LuresMultiSelect
            {...form.getInputProps('lureIdsActive')}
            targetInsectId={targetInsectId}
            disabled={form.values.lureAction === initialValues.lureAction}
          />
        </Input.Wrapper>

        {/* Liner and Coroplast inputs */}
        {trapConsumables.map((trapConsumable) => {
          const consumableActionKey = `${trapConsumable}Action` as keyof FormValues

          return (
            <Input.Wrapper
              css={{ marginTop: 20 }}
              key={consumableActionKey}
              label={getTrapConsumableLabel(trapConsumable)}
            >
              <R.Group {...form.getInputProps(consumableActionKey)}>
                <Group mt="xs" position="apart" style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 10 }}>
                    {nodeConsumablesStatus[
                      `is${upperFirst(trapConsumable)}Installed` as keyof TNodeConsumablesStatus
                    ] ? (
                      <>
                        <Radio
                          value={nodeConsumableActions.SWAP}
                          label={translate.phrases.placeholder('Swap')}
                        />
                        <Radio
                          value={nodeConsumableActions.REMOVE}
                          label={translate.phrases.placeholder('Remove')}
                        />
                      </>
                    ) : (
                      <>
                        <Radio
                          value={nodeConsumableActions.INSTALL}
                          label={translate.phrases.placeholder('Install')}
                        />
                      </>
                    )}
                  </div>

                  {form.isDirty(`${consumableActionKey}`) && (
                    <ClearButton
                      onClick={() =>
                        handleOnClearSelection({
                          [consumableActionKey]: initialValues[consumableActionKey],
                        })
                      }
                    />
                  )}
                </Group>
              </R.Group>
            </Input.Wrapper>
          )
        })}
        {/* Battery input */}
        <Input.Wrapper css={{ marginTop: 20 }} id="batteryAction" label={getTrapConsumableLabel('battery')}>
          <R.Group {...form.getInputProps('batteryAction')}>
            <Group mt="xs" position="apart" style={{ display: 'flex', alignItems: 'center', height: 40 }}>
              <div style={{ display: 'flex', gap: 10 }}>
                <Radio value={nodeConsumableActions.SWAP} label={translate.phrases.placeholder('Swap')} />
              </div>

              {form.isDirty('batteryAction') && (
                <ClearButton
                  onClick={() =>
                    handleOnClearSelection({
                      batteryAction: initialValues.batteryAction,
                    })
                  }
                />
              )}
            </Group>
          </R.Group>
        </Input.Wrapper>
      </div>

      <Footer
        onPrevious={handleBack}
        onNext={handleSubmit}
        nextButtonLabel={translate.phrases.placeholder('Submit')}
        disableNextButton={isLoading || !hasFormChanged}
      />
    </>
  )
}
