import type { ReactNode } from 'react'

export const Body = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={{
        padding: '0 5px',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          height: '400px',
          overflow: 'auto',
          boxShadow: '0 0 5px -2px rgba(0, 0, 0, 0.38)',
          backgroundColor: 'white',
          padding: '10px 30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {children}
      </div>
    </div>
  )
}
