import { translate } from 'i18n/i18n'
import { checkAuthorization } from 'utils/checkAuthorization'
import { getIntercomPrePopulatedMessage } from 'utils/getIntercomPrePopulatedMessage'
import { MenuButton } from '../ActionMenu/MenuButton/MenuButton'
import { openHelpGuideModal } from '../HelpGuideModal/HelpGuideModal'
import { openNodeConsumableModal } from '../NodeConsumableModal'
import { openNodeMaintenanceModal } from '../NodeMaintenanceModal/NodeMaintenanceModal'
import { openNodeRemoveModal } from '../NodeRemoveModal/NodeRemoveModal'
import { openNodeSwapModal } from '../NodeSwapModal/NodeSwapModal'
import type { TActiveGateway, TActiveNode, TPlannedGateway, TPlannedNode } from '../types'
import { checkRepositionPermissions } from './checkRepositionPermissions'
import { NO_RELOCATION_WARNING_NODE_TYPES } from './constants/nodeType'
import { isSemiosGatewayType } from './getNodeNetworkType'
import { getNodeTypeLabel } from './getNodeTypeLabel'
import { isPlannedNode } from './isPlannedNode'
import { openNodeRepositionModal } from './openNodeRepositionModal'
import { showModalWithCachedCheckbox } from './showModalWithCachedCheckbox'

export function getNodeActions(node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway) {
  const equipmentTypeLabel = getNodeTypeLabel(
    (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType,
  )

  const isTrapNode = ['dn_trp', 'trp'].includes((node as TActiveNode | TPlannedNode).nodeType.toLowerCase())
  const prePopulatedMessage = getIntercomPrePopulatedMessage({ node: node }) ?? ''

  const handleClickReposition = () => {
    const key = 'repositionNodeWarning'

    if (
      !localStorage.getItem(key) &&
      !isPlannedNode(node) &&
      (node as TActiveNode)?.nodeType &&
      !NO_RELOCATION_WARNING_NODE_TYPES.includes((node as TActiveNode).nodeType)
    ) {
      showModalWithCachedCheckbox({
        title: 'Reposition Equipment',
        DescriptionElement: (
          <div>
            {translate.phrases.placeholder(
              'Changing the Device Location after install may result in data loss.',
            )}
          </div>
        ),
        key,
        onOk: () => openNodeRepositionModal(node),
      })

      return
    }

    openNodeRepositionModal(node)
  }

  return (
    <>
      {/* TODO enable this feature once https://github.com/semiosBIO/app-platform/pull/662 */}
      {/* {!isPlannedNode(node) && isDeviceableNode((node as TActiveNode).nodeType) && (
        <MenuButton
          label={translate.phrases.placeholder('Devices Management')}
          description={translate.phrases.placeholder('Manage Devices')}
          onClick={() => openNodeDeviceModal((node as TActiveNode).nodeIdentifier)}
        />
      )} */}
      {!isPlannedNode(node) && (
        <MenuButton
          label={translate.phrases.placeholder('{{equipmentType}} Maintenance', {
            equipmentType: equipmentTypeLabel,
          })}
          description={translate.phrases.placeholder('View maintenance actions')}
          onClick={() => openNodeMaintenanceModal(node as TActiveNode)}
        />
      )}

      <MenuButton
        label={translate.phrases.placeholder('Reposition', {
          equipmentType: equipmentTypeLabel,
        })}
        description={translate.phrases.placeholder('Reposition equipment')}
        onClick={handleClickReposition}
        disabled={!checkRepositionPermissions(node)}
      />

      {isTrapNode && !isPlannedNode(node) && (
        <MenuButton
          label={translate.phrases.placeholder('Manage Trap Consumables', {
            equipmentType: equipmentTypeLabel,
          })}
          disabled={
            !checkAuthorization({
              permission: 'EDIT_SSC_UPDATE_NODE_CONFIG',
              entity: (node as TActiveNode).propertyId,
            }) || !checkAuthorization({ permission: 'VIEW_SSC_LURES', entity: 'FOR_ANY_ENTITY' })
          }
          description={translate.phrases.placeholder('Manage lures, liners, coroplasts and battery')}
          onClick={() => openNodeConsumableModal()}
        />
      )}

      {!isPlannedNode(node) && !isSemiosGatewayType(node as TActiveGateway | TActiveNode) && (
        <MenuButton
          label={translate.phrases.placeholder('Swap Equipment')}
          description={translate.phrases.placeholder('Swap {{equipmentType}}', {
            equipmentType: equipmentTypeLabel,
          })}
          onClick={() => openNodeSwapModal()}
          disabled={
            !checkAuthorization({
              permission: 'EDIT_SSC_SWAP_NODE',
              entity: (node as TActiveNode).propertyId,
            }) || !checkAuthorization({ permission: 'VIEW_SSC_SWAP_NODE_REASONS', entity: 'FOR_ANY_ENTITY' })
          }
        />
      )}

      {!isPlannedNode(node) && (
        <MenuButton
          label={translate.phrases.placeholder('Remove Equipment', {
            equipmentType: equipmentTypeLabel,
          })}
          disabled={
            !checkAuthorization({
              permission: 'EDIT_SSC_REMOVE_NODE',
              entity: (node as TActiveNode).propertyId,
            }) ||
            !checkAuthorization({ permission: 'VIEW_SSC_REMOVE_NODE_REASONS', entity: 'FOR_ANY_ENTITY' })
          }
          description={translate.phrases.placeholder('Remove {{equipmentType}}', {
            equipmentType: equipmentTypeLabel,
          })}
          onClick={() => openNodeRemoveModal(node as TActiveNode)} //TODO: Fix this, do we allow to remove a gateway?
        />
      )}

      <MenuButton
        label={translate.phrases.placeholder('Guides and Resources')}
        description={translate.phrases.placeholder('Access step-by-step instructions')}
        onClick={() => {
          openHelpGuideModal({
            equipmentType: (node as TActiveNode).nodeType,
          })
        }}
      />

      <MenuButton
        label={translate.phrases.placeholder('Contact Support')}
        description={translate.phrases.placeholder('Reach out to our team for help')}
        onClick={() => {
          // @ts-ignore
          window.Intercom('showNewMessage', prePopulatedMessage)
        }}
      />
    </>
  )
}
