import { Select } from '@mantine/core'
import { sortSections } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateNavigationMenu'
import { WindMachineSecondaryDropdown } from 'App/Map/MapControls/ValueRow/WindMachineSecondaryDropdown/WindMachineSecondaryDropdown'
import { translate } from 'i18n/i18n'
import { cloneDeep } from 'lodash'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { defaultMapVisualValueGroup } from 'stores/mapControlsStore/mapVisualValueGroup'
import type { MAP_VISUAL } from 'stores/mapControlsStore/types'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  selectedValueGroupsStore,
  valueGroupToSectionDictionary,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { AlmondBloomSecondaryDropdown } from './AlmondBloomSecondaryDropdown/AlmondBloomSecondaryDropdown'
import { AlternariaSecondaryDropdown } from './AlternariaSecondaryDropdown/AlternariaSecondaryDropdown'
import { BeeHoursSecondaryDropdown } from './BeeHoursSecondaryDropdown/BeeHoursSecondaryDropdown'
import { ChillSecondaryDropdown } from './ChillSecondaryDropdown/ChillSecondaryDropdown'
import { EquipmentStatusSecondaryDropdown } from './EquipmentStatusSecondaryDropdown/EquipmentStatusSecondaryDropdown'
import { EvapotranspirationSecondaryDropdown } from './EvapotranspirationSecondaryDropdown/EvapotranspirationSecondaryDropdown'
import { FruitGrowthSecondaryDropdown } from './FruitGrowthSecondaryDropdown/FruitGrowthSecondaryDropdown'
import { InsectDegreeDaysSecondaryDropdown } from './InsectDegreeDaysSecondaryDropdown/InsectDegreeDaysSecondaryDropdown'
import { InsectTrapCatchesSecondaryDropdown } from './InsectTrapCatchesSecondaryDropdown/InsectTrapCatchesSecondaryDropdown'
import { IrrigationActivitySecondaryDropdown } from './IrrigationActivitySecondaryDropdown/IrrigationActivitySecondaryDropdown'
import { PrecipitationSecondaryDropdown } from './PrecipitationSecondaryDropdown/PrecipitationSecondaryDropdown'
import { SoilSecondaryDropdown } from './SoilSecondaryDropdown/SoilSecondaryDropdown'
import { TemperatureSecondaryDropdown } from './TemperatureSecondaryDropdown/TemperatureSecondaryDropdown'

const SecondaryChild: Record<TValueGroup, React.ReactNode> = {
  air_temperature: <TemperatureSecondaryDropdown />,
  almond_bloom: <AlmondBloomSecondaryDropdown />,
  almond_hull_split: null,
  alternaria: <AlternariaSecondaryDropdown />,
  atmospheric_pressure: null,
  bee_hours: <BeeHoursSecondaryDropdown />,
  chill: <ChillSecondaryDropdown />,
  conditions: null,
  wind_machine: <WindMachineSecondaryDropdown />,
  degree_days_insect_id_1: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_10: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_11: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_12: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_13: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_14: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_15: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_16: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_17: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_18: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_19: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_2: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_20: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_23: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_24: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_25: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_26: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_27: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_28: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_29: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_30: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_3: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_4: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_6: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_7: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_8: <InsectDegreeDaysSecondaryDropdown />,
  degree_days_insect_id_9: <InsectDegreeDaysSecondaryDropdown />,
  dew_point: null,
  equipment_status: <EquipmentStatusSecondaryDropdown />,
  evapotranspiration: <EvapotranspirationSecondaryDropdown />,
  fruit_growth: <FruitGrowthSecondaryDropdown />,
  plant_stress: null,
  humidity: null,
  irrigation_activity: <IrrigationActivitySecondaryDropdown />,
  larval_trend_insect_id_1: null,
  larval_trend_insect_id_11: null,
  larval_trend_insect_id_20: null,
  larval_trend_insect_id_3: null,
  larval_trend_insect_id_6: null,
  larval_trend_insect_id_7: null,
  larval_trend_insect_id_9: null,
  leaf_wetness: null,
  ndvi: null,
  precipitation: <PrecipitationSecondaryDropdown />,
  soil: <SoilSecondaryDropdown />,
  spray_conditions: null,
  trap_catches_insect_id_1: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_10: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_11: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_12: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_15: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_16: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_17: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_19: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_2: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_20: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_25: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_27: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_28: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_29: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_30: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_6: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_7: <InsectTrapCatchesSecondaryDropdown />,
  trap_catches_insect_id_9: <InsectTrapCatchesSecondaryDropdown />,
  walnut_blight: null,
  wet_bulb: null,
  wind: null,
}

export const ValueRow = () => {
  const primaryValueGroup = usePrimaryValueGroup()
  const selectedValueGroups = selectedValueGroupsStore.useSelector((s) => s.selectedValueGroups)
  const isPropertyPlaybackActive = smallStore.useSelector((s) => s.showPropertyPlayback)

  const data = useMemo(() => {
    return Object.entries(selectedValueGroups)
      .flatMap(([valueGroup, active]) => {
        const label = (() => {
          if (valueGroup.includes('degree_days_insect_id_'))
            return translate.phrases.banyanApp('{{insectName}} Degree Days', {
              insectName: getInsectNameFromId(Number(valueGroup.replace('degree_days_insect_id_', ''))),
            })

          // TOD0: hack until we have regional trap stuff for the map
          if (valueGroup.includes('trap_catches_insect_id_') && !isUserOnlyAFreeRegionalUser())
            return translate.phrases.banyanApp('{{insectName}} Trap Catches', {
              insectName: getInsectNameFromId(Number(valueGroup.replace('trap_catches_insect_id_', ''))),
            })

          if (valueGroup === 'air_temperature') return translate.phrases.banyanApp('Temperature')

          if (valueGroup === 'almond_bloom') return translate.phrases.banyanApp('Almond Bloom')

          if (valueGroup === 'almond_hull_split')
            return translate.phrases.templates('{{labelA}} ({{labelB}})', {
              labelA: translate.phrases.banyanApp('Almond Hull Split'),
              labelB: translate.phrases.banyanApp('Nonpareil'),
            })

          if (valueGroup === 'alternaria') return translate.phrases.banyanApp('Alternaria')

          if (valueGroup === 'bee_hours') return translate.phrases.banyanApp('Bee Hours')

          if (valueGroup === 'chill') return translate.phrases.banyanApp('Chill')

          if (valueGroup === 'dew_point') return translate.phrases.banyanApp('Dew Point')

          if (valueGroup === 'evapotranspiration') return translate.phrases.banyanApp('Evapotranspiration')

          if (valueGroup === 'fruit_growth') return translate.phrases.banyanApp('Fruit Growth')

          if (valueGroup === 'plant_stress') return translate.phrases.banyanApp('Plant Stress')

          if (valueGroup === 'wet_bulb') return translate.phrases.banyanApp('Wet Bulb')

          if (valueGroup === 'humidity') return translate.phrases.banyanApp('Humidity')

          if (valueGroup === 'equipment_status') return translate.phrases.placeholder('Equipment Status')

          // TODO: conditions

          if (valueGroup === 'precipitation') return translate.phrases.banyanApp('Rain')

          if (valueGroup === 'soil') return translate.phrases.banyanApp('Soil')

          if (valueGroup === 'spray_conditions') return translate.phrases.banyanApp('Spray Conditions')

          if (valueGroup === 'wind') return translate.phrases.banyanApp('Wind')

          if (valueGroup === 'wind_machine') return translate.phrases.banyanApp('Wind Machine')

          if (valueGroup === 'irrigation_activity') return translate.phrases.banyanApp('Irrigation Activity')

          // TODO: if it's not here, we haven't implemented it yet. This will clean up the UI along with our filter later
          return ''
        })()

        const section = valueGroupToSectionDictionary[valueGroup as TValueGroup]

        const sectionLabel = (() => {
          if (section === 'environment') return translate.phrases.banyanApp('Environment')

          if (section === 'water-management') return translate.phrases.banyanApp('Water Management')

          if (section === 'crop-development') return translate.phrases.banyanApp('Crop Development')

          if (section === 'sprays') return translate.phrases.banyanApp('Sprays')

          if (section === 'insects') return translate.phrases.banyanApp('Pests')

          if (section === 'equipment') return translate.phrases.placeholder('Equipment')

          if (section === 'diseases') return translate.phrases.banyanApp('Diseases')
        })()

        if (!active || !label) return []

        return {
          section,
          group: sectionLabel,
          value: valueGroup,
          label,
        }
      })
      .sort((a, b) => sortSections(a.section, b.section) || alphabeticalSort(a.label, b.label))
  }, [primaryValueGroup, selectedValueGroups])

  if (!data.length) return null

  return (
    <>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Select
          // Property playback is only for temperature for now
          disabled={isPropertyPlaybackActive}
          data={data}
          onChange={(newValueGroup: TValueGroup) => {
            mapControlsStore.setState((s) => {
              const newMapVisualsValueGroup = cloneDeep(s.mapVisualValueGroup)

              Object.entries(newMapVisualsValueGroup).forEach(([mapVisual, valueGroupForMapVisual]) => {
                if (!valueGroupForMapVisual[newValueGroup]) {
                  valueGroupForMapVisual[newValueGroup] =
                    defaultMapVisualValueGroup[mapVisual as MAP_VISUAL]?.[newValueGroup]
                }
              })

              return {
                ...s,
                mapVisualValueGroup: newMapVisualsValueGroup,
              }
            })

            selectedValueGroupsStore.setState((s) => {
              return { ...s, primaryValueGroup: newValueGroup }
            })
          }}
          css={{ width: '100%' }}
          styles={{ ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER }}
          value={primaryValueGroup}
          withinPortal
        />
      </div>
      {!!SecondaryChild[primaryValueGroup as TValueGroup] && (
        <div css={{ marginTop: 10 }}>{SecondaryChild[primaryValueGroup as TValueGroup]}</div>
      )}
    </>
  )
}
