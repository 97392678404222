export const AlmaPill = <T,>({
  textContent,
  onClick,
  detail,
}: {
  textContent: string
  onClick?: (detail: T) => void
  detail?: T
}) => {
  return (
    <div
      css={{
        color: 'rgba(0, 0, 0, 1)',
        padding: '10px',
        border: '1px rgba(0, 0, 0, 1) solid',
        borderRadius: '15px',
        cursor: 'pointer',
      }}
      onClick={() => onClick?.(detail as T)}
    >
      {textContent}
    </div>
  )
}
