import { LoadingOverlay } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { StackedChart } from 'components/StackedChart/StackedChart'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'
import { useIsRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { mergeApiArgs } from '../_utils/mergeApiArgs'
import { useApiValuesTimeseries } from '../_utils/useApiValuesTimeseries/useApiValuesTimeseries'
import { useTimezoneForSelectedPropertyOrRegion } from '../_utils/useTimezoneForSelectedPropertyOrRegion'
import { ChildrenUpper } from './ChildrenUpper/ChildrenUpper'
import { useConvertCompareSeasonsData } from './useConvertCompareSeasonsData'
import * as almondBloom from './_utils/by-domain/almondBloom/almondBloom'
import * as alternaria from './_utils/by-domain/alternaria/alternaria'
import * as beeHours from './_utils/by-domain/beeHours/beeHours'
import * as chill from './_utils/by-domain/chill/chill'
import * as conditions from './_utils/by-domain/conditions/conditions'
import * as dewPoint from './_utils/by-domain/dewPoint/dewPoint'
import * as evapotranspiration from './_utils/by-domain/evapotranspiration/evapotranspiration'
import * as fruitGrowth from './_utils/by-domain/fruitGrowth/fruitGrowth'
import * as humidity from './_utils/by-domain/humidity/humidity'
import * as insectDegreeDays from './_utils/by-domain/insectDegreeDays/insectDegreeDays'
import * as insectLarvalTrend from './_utils/by-domain/insectLarvalTrend/insectLarvalTrend'
import * as insectTrapCatches from './_utils/by-domain/insectTrapCatches/insectTrapCatches'
import * as irrigationActivity from './_utils/by-domain/irrigationActivity/irrigationActivity'
import * as plantStress from './_utils/by-domain/plantStress/plantStress'
import * as precipitation from './_utils/by-domain/precipitation/precipitation'
import * as soil from './_utils/by-domain/soil/soil'
import * as sprayConditions from './_utils/by-domain/sprayConditions/sprayConditions'
import * as temperature from './_utils/by-domain/temperature/temperature'
import * as walnutBlight from './_utils/by-domain/walnutBlight/walnutBlight'
import * as wetBulb from './_utils/by-domain/wetBulb/wetBulb'
import * as wind from './_utils/by-domain/wind/wind'
import * as windMachine from './_utils/by-domain/windMachine/windMachine'
import { createContent } from './_utils/createContent'
import { useSoilDepthVisibility } from './_utils/useSoilDepthVisibility'

export const StackemCharts = ({
  containerWidth,
  preventFetch,
  selectedValueGroups,
}: {
  containerWidth: number
  preventFetch: boolean
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}) => {
  useSoilDepthVisibility()

  const timezone = useTimezoneForSelectedPropertyOrRegion()
  const selectedFieldAssets = selectedFieldAssetsStore.useSelector((s) => s)
  const showRegionalData = useIsRegionDataEnabledForCharts()

  const {
    compareSeasonsInterval,
    dateFrom,
    dateTo,
    detailsPanelStoreContentsThatShouldTriggerARefetch,
    detailsPanelStoreContentsThatShouldRedrawCharts,
    hasExtraRightPadding,
  } = detailsPanelStore.useSelector((s) => {
    const {
      showSelectedSoilDepths,
      chillMarVsMay,
      chillSepVsNov,
      compareSeasonsInterval,
      dateFrom,
      dateTo,
      selectedTab,
      larvalTrendShowValues,
      keyForRefreshing,
      keyForRedrawing,
      precipitationAggregation,
      soilVisibility,
      hasExtraRightPadding,
    } = s

    return {
      compareSeasonsInterval,
      detailsPanelStoreContentsThatShouldTriggerARefetch: {
        precipitationAggregation,
        keyForRefreshing,
      },
      detailsPanelStoreContentsThatShouldRedrawCharts: {
        showSelectedSoilDepths,
        chillMarVsMay,
        chillSepVsNov,
        selectedTab,
        keyForRefreshing,
        larvalTrendShowValues,
        precipitationAggregation,
        soilVisibility,
        keyForRedrawing,
      },
      dateFrom,
      dateTo,
      hasExtraRightPadding,
    }
  })

  const { userDetailsStoreContentsThatShouldRedrawCharts } = userDetailsStore.useSelector((s) => {
    const { rain } = s

    return {
      userDetailsStoreContentsThatShouldRedrawCharts: {
        rain,
      },
    }
  })

  const { unitTypeUserSettings } = userDetailsStore.useSelector(
    ({ depth, rain, speed, temperature, pressure }) => ({
      unitTypeUserSettings: { depth, rain, speed, temperature, pressure },
    }),
  )

  const commonApiParams = {
    selectedFieldAssets,
    selectedValueGroups,
  }

  const mergedApiArgs = mergeApiArgs(
    almondBloom.apiArgs(commonApiParams),
    alternaria.apiArgs(commonApiParams),
    beeHours.apiArgs(commonApiParams),
    chill.apiArgs(commonApiParams),
    conditions.apiArgs(commonApiParams),
    dewPoint.apiArgs(commonApiParams),
    evapotranspiration.apiArgs(commonApiParams),
    fruitGrowth.apiArgs(commonApiParams),
    humidity.apiArgs(commonApiParams),
    insectDegreeDays.apiArgs(commonApiParams),
    insectLarvalTrend.apiArgs(commonApiParams),
    insectTrapCatches.apiArgs(commonApiParams),
    irrigationActivity.apiArgs(commonApiParams),
    plantStress.apiArgs(commonApiParams),
    precipitation.apiArgs(commonApiParams),
    soil.apiArgs(commonApiParams),
    sprayConditions.apiArgs(commonApiParams),
    temperature.apiArgs(commonApiParams),
    walnutBlight.apiArgs(commonApiParams),
    wetBulb.apiArgs(commonApiParams),
    wind.apiArgs(commonApiParams),
    windMachine.apiArgs(commonApiParams),
  )

  const { data, loading, updateData } = useApiValuesTimeseries({
    args: { dateFrom, dateTo, includeSource: true, ...mergedApiArgs },
    preventFetch,
    watchers: [
      JSON.stringify(detailsPanelStoreContentsThatShouldTriggerARefetch),
      JSON.stringify(mergedApiArgs),
    ],
  })

  const { data: compareSeasonsData, loading: compareSeasonsLoading } = useApiValuesTimeseries({
    args: {
      dateFrom: getIsoWithoutTheZ(moment.tz(dateFrom, timezone).subtract(compareSeasonsInterval, 'years')),
      dateTo: getIsoWithoutTheZ(moment.tz(dateTo, timezone).subtract(compareSeasonsInterval, 'years')),
      includeSource: true,
      ...mergedApiArgs,
    },
    preventFetch: preventFetch || compareSeasonsInterval === 0,
    watchers: [JSON.stringify(detailsPanelStoreContentsThatShouldTriggerARefetch)],
  })

  const convertedCompareSeasonsData = useConvertCompareSeasonsData(
    compareSeasonsData as routes.Values.Response,
    compareSeasonsInterval,
  )

  const content = useMemo(
    () =>
      createContent({
        data: data as routes.Values.Response,
        compareSeasonsData:
          compareSeasonsInterval === 0 || showRegionalData
            ? {}
            : (convertedCompareSeasonsData as routes.Values.Response),
        selectedValueGroups,
        selectedFieldAssets,
        updateData,
      }),
    [
      convertedCompareSeasonsData,
      compareSeasonsInterval,
      data,
      JSON.stringify(selectedFieldAssets),
      selectedValueGroups,
      JSON.stringify(detailsPanelStoreContentsThatShouldRedrawCharts),
      JSON.stringify(userDetailsStoreContentsThatShouldRedrawCharts),
      JSON.stringify(unitTypeUserSettings),
      showRegionalData,
    ],
  )

  return (
    <>
      <LoadingOverlay visible={loading || compareSeasonsLoading} />
      <StackedChart
        containerWidth={containerWidth}
        content={content}
        childrenUpper={
          <ChildrenUpper containerWidth={containerWidth} selectedValueGroups={selectedValueGroups} />
        }
        key={selectedFieldAssets.property ?? selectedFieldAssets.region}
        timezone={timezone}
        navigatorKey={
          JSON.stringify(selectedFieldAssets) +
          detailsPanelStoreContentsThatShouldRedrawCharts.keyForRefreshing
        }
        hasExtraRightPadding={hasExtraRightPadding}
      />
    </>
  )
}
