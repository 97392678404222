import type { ReactNode } from 'react'

export const Head = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={{
        padding: '5px 5px 0 5px',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          borderRadius: '15px 15px 0 0',
          boxShadow: '0 0 5px -2px rgba(0, 0, 0, 0.38)',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          height: '50px',
          position: 'relative',
        }}
      >
        {children}
      </div>
    </div>
  )
}
