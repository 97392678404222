import { NumberInput } from '@mantine/core'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

const notInversionColor = colors.grey200

export const TemperatureInversionHeatmapRow = () => {
  const heatmapExtremes = mapControlsStore.useSelector((s) => s.heatmapExtremes)
  const heatmapColoringFromStore = mapControlsStore.useSelector((s) => s.heatmapColoring)
  const heatmapMin = 0
  const heatmapMax = heatmapExtremes.max
  const heatmapColoring = [notInversionColor, ...heatmapColoringFromStore]

  const unitConverterFunctionForSaving = (val?: number | null) =>
    unitConverter.temperatureInversion(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    })

  const unitConverterFunctionForRendering = unitConverter.temperatureInversion
  const suffix = unitConverterFunctionForRendering?.()?.suffix()

  return (
    <div
      css={{
        width: '100%',
        background: 'transparent',
        borderRadius: 3,
        display: 'flex',
      }}
    >
      <div>
        <NumberInput
          value={heatmapMin}
          disabled
          css={{ maxWidth: 44 }}
          styles={{ input: { padding: 0, textAlign: 'center' } }}
          hideControls
        />
      </div>
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            marginTop: 4,
            width: '100%',
            display: 'flex',
            background: colors.grey50,
            padding: '8px 6px',
          }}
        >
          {heatmapColoring.map((color, i) => (
            <div css={{ flex: 1, background: color, height: 12 }} key={i} />
          ))}
        </div>
        {!!suffix && <div css={{ fontSize: 12, marginTop: 4 }}>{suffix}</div>}
      </div>
      <div>
        <NumberInput
          min={
            unitConverterFunctionForRendering
              ? (unitConverterFunctionForRendering(heatmapMin).value() as number)
              : heatmapMin
          }
          value={
            unitConverterFunctionForRendering
              ? (unitConverterFunctionForRendering(heatmapMax).value() as number)
              : heatmapMax
          }
          css={{ maxWidth: 44 }}
          styles={{ input: { padding: 0, textAlign: 'center' } }}
          hideControls
          onChange={(newMax) => {
            if (newMax === '') return

            const newValue = unitConverterFunctionForSaving
              ? (unitConverterFunctionForSaving(newMax).value() as number)
              : newMax

            mapControlsStore.setState((s) => ({
              ...s,
              heatmapExtremes: {
                ...s.heatmapExtremes,
                max: newValue,
              },
              manualExtremes: true,
            }))
          }}
        />
      </div>
    </div>
  )
}
