import { alphabeticalSort } from 'utils/alphabeticalSort'
import type { AnyNode, TActiveGateway, TActiveNode, TNodeLog, TPlannedNode } from '../../types'
import type { TServiceCenterStore } from '../serviceCenterStore'

export const selectors = {
  getMapEquipmentStatuses: (s: TServiceCenterStore) => s.mapEquipmentStatuses,
  getMapEquipmentTypes: (s: TServiceCenterStore) => s.mapEquipmentTypes,
  getMapEquipmentDevices: (s: TServiceCenterStore) => s.mapEquipmentDevices,
  getPlannedNodes: (s: TServiceCenterStore) => s.plannedNodes,
  getActiveNodes: (s: TServiceCenterStore) => s.activeNodes,
  getPlannedAndActiveNodes: (s: TServiceCenterStore) => [...s.plannedNodes, ...s.activeNodes],
  getSelectedEquipmentId: (s: TServiceCenterStore) =>
    s.selectedPlannedEquipmentIds[0] || s.selectedActiveEquipmentIds[0],
  getSelectedEquipmentNode: (s: TServiceCenterStore): AnyNode | null => {
    if (s.selectedPlannedEquipmentIds[0]) {
      return (
        s.plannedNodes.find((node) => (node as TPlannedNode).id === s.selectedPlannedEquipmentIds[0]) || null
      )
    } else if (s.selectedActiveEquipmentIds[0]) {
      return (
        s.activeNodes.find(
          (node) =>
            ((node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier) ===
            s.selectedActiveEquipmentIds[0],
        ) || null
      )
    } else return null
  },
  getNodeLogs: (s: TServiceCenterStore) => s.nodeLogs,
  getNodeStatuses: (s: TServiceCenterStore) => s.nodeStatuses,
  getServiceIssues: (s: TServiceCenterStore) => s.serviceIssues,
  getSelectedEquipmentLastLog: (s: TServiceCenterStore): TNodeLog | null => {
    if (s.selectedActiveEquipmentIds[0]) {
      return s.nodeLogs[s.selectedActiveEquipmentIds[0]] || null
    } else return null
  },
  getSelectedEquipmentServiceIssues: (s: TServiceCenterStore) => {
    if (s.selectedActiveEquipmentIds[0]) {
      return s.serviceIssues[s.selectedActiveEquipmentIds[0]] || []
    } else return []
  },
  getHelpGuides: (s: TServiceCenterStore) => s.helpGuides,
  getSwapReasonsBySelectedNode: (s: TServiceCenterStore) => {
    const selectedNode = selectors.getSelectedEquipmentNode(s)

    if (selectedNode && Object.keys(s.swapReasons).includes((selectedNode as TActiveNode).nodeType))
      return s.swapReasons[(selectedNode as TActiveNode).nodeType].sort((a, b) => {
        if (a.swapId === 'OTHER') return 1

        if (b.swapId === 'OTHER') return -1

        return alphabeticalSort(a.swapReason, b.swapReason)
      })

    return []
  },
  getRemoveReasonsBySelectedNode: (s: TServiceCenterStore) => {
    const selectedNode = selectors.getSelectedEquipmentNode(s)

    if (selectedNode && Object.keys(s.removeReasons).includes((selectedNode as TActiveNode).nodeType))
      return s.removeReasons[(selectedNode as TActiveNode).nodeType].sort((a, b) => {
        if (a.removeId === 'OTHER') return 1

        if (b.removeId === 'OTHER') return -1

        return alphabeticalSort(a.removeReason, b.removeReason)
      })

    return []
  },
  getLures: (s: TServiceCenterStore) => s.lures,
  getLuresAsArray: (s: TServiceCenterStore) => {
    return Object.values(s.lures).sort((a, b) => alphabeticalSort(a.lureName, b.lureName))
  },
}
