import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
  TValuesCurrentRegionValueTypes,
} from 'stores/mapControlsStore/types'
import { isRegionDataEnabled } from 'utils/useIsRegionDataEnabled'
import type { TCurrentValuesMapProcessedCaches } from '../_types'
import { getPropertyIdsForAllPropertiesInVisibleRegions } from './getPropertyIdsForAllPropertiesInVisibleRegions'

export const generateUsualStyleAPIArgs = ({
  heatmapExtremesForAllPropertiesInVisibleRegions,
  processedCaches,
  block,
  inBlockPoint,
  outOfBlockPoint,
  property,
  trapPoint,
  region,
}: {
  heatmapExtremesForAllPropertiesInVisibleRegions: boolean
  processedCaches: TCurrentValuesMapProcessedCaches
  block?: {
    valueType: TValuesCurrentBlocksValueTypes
  }
  inBlockPoint?: {
    valueType: TValuesCurrentPointsValueTypes | TValuesCurrentHeatmapPointsValueTypes
  }
  outOfBlockPoint?: {
    valueType: TValuesCurrentPointsValueTypes
  }
  property?: {
    valueType: TValuesCurrentPropertiesValueTypes
  }
  trapPoint?: {
    valueType: TValuesCurrentPointsValueTypes
  }
  region?: {
    valueType: TValuesCurrentRegionValueTypes
  }
}): routes.ValuesCurrent.Request => {
  const returner: routes.ValuesCurrent.Request = {}

  if (block && !!processedCaches.blockValues.itemsWithinView.length) {
    returner.blocks = {
      blockIds: processedCaches.blockValues.itemsWithinView.map((b) => Number(b.id)),
      values: { [block.valueType]: true },
    }
  }

  if (inBlockPoint && !!processedCaches.blockScdsValues.itemsWithinView.length) {
    returner.heatmap_points = {
      blockIds: processedCaches.blockScdsValues.itemsWithinView.map((b) => Number(b.id)),
      values: { [inBlockPoint.valueType]: true },
    }
  }

  if (property && !!processedCaches.propertyValues.itemsWithinView.length) {
    const propertyIds = heatmapExtremesForAllPropertiesInVisibleRegions
      ? getPropertyIdsForAllPropertiesInVisibleRegions(
          processedCaches.propertyValues.itemsWithinView.map((p) => Number(p.id)),
        )
      : processedCaches.propertyValues.itemsWithinView.map((p) => Number(p.id))

    returner.properties = {
      propertyIds,
      values: { [property.valueType]: true },
    }
  }

  if (region && isRegionDataEnabled()) {
    const regionIds = processedCaches.regionValues.itemsWithinView.map((r) => r.id)

    if (regionIds.length) {
      returner.regions = {
        regionIds: regionIds,
        values: {
          [region.valueType]: true,
        },
      }
    }
  }

  const shouldFetchPoints = inBlockPoint || outOfBlockPoint || trapPoint

  if (shouldFetchPoints) {
    const lngLatsToFetch: TFieldAssetKeyTypes.TLngLat[] = []
    const pointsValuesToFetch: NonNullable<routes.ValuesCurrent.Request['points']>['values'] = {}

    if (inBlockPoint && !!processedCaches.stationValues.itemsWithinView.length) {
      processedCaches.stationValues.itemsWithinView.forEach((s) => {
        lngLatsToFetch.push(s.meta.lngLat)
      })

      // @ts-ignore
      pointsValuesToFetch[inBlockPoint.valueType] = true
    }

    if (outOfBlockPoint && !!processedCaches.stationValues.itemsWithinView.length) {
      processedCaches.stationValues.itemsWithinView.forEach((s) => {
        lngLatsToFetch.push(s.meta.lngLat)
      })

      // @ts-ignore
      pointsValuesToFetch[outOfBlockPoint.valueType] = true
    }

    if (trapPoint && !!processedCaches.trapsValues.itemsWithinView.length) {
      processedCaches.trapsValues.itemsWithinView.forEach((t) => {
        lngLatsToFetch.push(t.meta.lngLat)
      })

      // @ts-ignore
      pointsValuesToFetch[trapPoint.valueType] = true
    }

    if (!!lngLatsToFetch.length) {
      returner.points = {
        lngLats: lngLatsToFetch,
        values: pointsValuesToFetch,
      }
    }
  }

  return returner
}
