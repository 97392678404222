import { Badge, useMantineTheme } from '@mantine/core'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import React, { useMemo } from 'react'
import { checkAuthorization } from 'utils/checkAuthorization'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { filterLuresByInsectId } from '../../utils/filterLuresByInsectId'

interface Props {
  value: number[]
  targetInsectId?: number | null
  disabled?: boolean
  onChange: (lureIds: number[]) => void
}

export const LuresMultiSelect: React.FC<Props> = ({
  value: defaultSelectedLureIds,
  targetInsectId = null,
  disabled = false,
  onChange,
}) => {
  const theme = useMantineTheme()
  const luresKeyById = serviceCenterStore.useSelector(serviceCenterStore.selectors.getLures)
  const lures = serviceCenterStore.useSelector(serviceCenterStore.selectors.getLuresAsArray)

  const lureOptions = useMemo(() => {
    return filterLuresByInsectId(lures, targetInsectId).map(({ lureId, lureName }) => ({
      value: lureId,
      label: lureName,
    }))
  }, [lures, targetInsectId])

  const lureViewable = checkAuthorization({ permission: 'VIEW_SSC_LURES', entity: 'FOR_ANY_ENTITY' })

  const selectedOptions = useMemo(() => {
    return (defaultSelectedLureIds ?? []).map((lureId) => ({
      value: lureId,
      label: luresKeyById[lureId].lureName,
    }))
  }, [defaultSelectedLureIds, luresKeyById])

  const handleSelectionChange = (selectedOptions: TOptions[]) => {
    const selectedLureIds = selectedOptions.map((option) => option.value) as number[]

    onChange(selectedLureIds)
  }

  return (
    <MultiSelect
      data={lureOptions}
      onChange={handleSelectionChange}
      placeholder={translate.phrases.placeholder('Select lure type(s)')}
      selectedData={selectedOptions}
      disabled={disabled || !lureViewable}
      optionValueDecorator={(label) => (
        <Badge
          css={{
            color: theme.colors.midnight,
            fontSize: '12px',
            fontWeight: 500,
            textTransform: 'none',
          }}
        >
          {label}
        </Badge>
      )}
    />
  )
}
