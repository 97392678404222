import type { CSSObject } from '@emotion/react'
import { useCallback, useRef } from 'react'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { smallStore, useScreenOrientation } from 'stores/smallStore'
import { useScreenSize } from 'utils/useScreenSize'
import { AlmaContextProvider } from './components/AlmaContext/AlmaContext'
import { AlmaInput } from './components/AlmaInput/AlmaInput'
import { Body } from './components/Body/Body'
import { ChatLog } from './components/ChatLog/ChatLog'
import { CloseButton } from './components/CloseButton/CloseButton'
import { Head } from './components/Head/Head'
import { Tail } from './components/Tail/Tail'

export const AlmaChat = () => {
  const bottomBodyScrollRef = useRef<HTMLDivElement>(null)
  const showAlmaChat = smallStore.useSelector((s) => s.showAlmaChat)
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)
  const { isWideScreen } = useScreenSize()
  const screenOrientation = useScreenOrientation()

  let scrollToBottomOfChatTimeoutId: NodeJS.Timeout | null = null

  const scrollToBottomOfChat = useCallback(() => {
    if (scrollToBottomOfChatTimeoutId) clearTimeout(scrollToBottomOfChatTimeoutId)

    scrollToBottomOfChatTimeoutId = setTimeout(
      () => bottomBodyScrollRef.current?.scrollIntoView({ behavior: 'smooth' }),
      200,
    )
  }, [bottomBodyScrollRef.current])

  let css: CSSObject

  if (showAlmaChat) {
    css = {
      position: 'fixed',
      bottom: 0,
    }

    if (screenOrientation === 'landscape') {
      css = {
        ...css,
        left: !menuCollapsed && isWideScreen ? '310px' : '5px',
        width: '420px',
      }
    } else {
      layersNavigationMenuStore.setState((s) => ({ ...s, menuCollapsed: true }))

      css = {
        ...css,
        width: '100%',
        zIndex: 101,
      }
    }
  } else {
    css = { display: 'none' }
  }

  return (
    <AlmaContextProvider scrollToBottomOfChat={scrollToBottomOfChat}>
      <div css={css}>
        <Head>
          <CloseButton />
        </Head>

        <Body>
          <ChatLog />

          <div ref={bottomBodyScrollRef}></div>
        </Body>

        <Tail>
          <AlmaInput />
        </Tail>
      </div>
    </AlmaContextProvider>
  )
}
