import type {
  Chart,
  TooltipFormatterContextObject,
  XAxisPlotBandsOptions,
  XAxisPlotLinesOptions,
} from 'highcharts'

export type TXAxisPlotLinesOptionsWithTooltipDisplay = XAxisPlotLinesOptions & {
  tooltipDisplay: (ctx: TooltipFormatterContextObject) => string
}

export type TXAxisPlotBandsOptionsWithTooltipDisplay = XAxisPlotBandsOptions & {
  tooltipDisplay: (ctx: TooltipFormatterContextObject) => string
  tooltipColor?: string
  plotbandInfoOnly?: boolean
}

export const buildTooltipPlotLinesIfAny = (ctx: TooltipFormatterContextObject) => {
  let template = ''

  /**
   * this block of code allows plotLines to render in tooltips if they
   * fall on the same date as the tooltip. This can be accomplished by
   * passing an argument of "tooltipDisplay" in the plotLine declaration.
   * We can wrap it all in a try-catch for safety, since it's pretty
   * hacky and likely brittle
   */
  const xAxes: Chart['xAxis'] = ctx?.points?.[0].series.chart.xAxis ?? []

  const plotLinesAndBands = xAxes
    // @ts-ignore
    .flatMap((xAxis) => xAxis?.plotLinesAndBands ?? [])
    .filter((pb) => {
      if (!ctx.x) return false

      const canShowInTooltip = !!pb.options.tooltipDisplay

      if (!canShowInTooltip) return false

      const min = pb.options.value ?? pb.options.from
      const max = pb.options.value ?? pb.options.to
      const isInDateRange = Number(ctx.x) >= Number(min) && Number(ctx.x) <= Number(max)

      return canShowInTooltip && isInDateRange
    })

  if (plotLinesAndBands.length) {
    const stuffToAddToTemplate = plotLinesAndBands.map(
      (pb: {
        options: TXAxisPlotLinesOptionsWithTooltipDisplay | TXAxisPlotBandsOptionsWithTooltipDisplay
      }) => {
        const plotLineWidth = 'width' in pb.options && pb.options.width ? pb.options.width : 2
        const plotLineColor = 'tooltipColor' in pb.options ? pb.options.tooltipColor : pb.options.color
        const plotbandInfoOnly = 'plotbandInfoOnly' in pb.options && pb.options.plotbandInfoOnly

        if (plotbandInfoOnly) {
          return `
          <span id="${pb.options.id}:START"></span>
          <div>
          ${pb.options.tooltipDisplay(ctx)}
          </div>
          <span id="${pb.options.id}:END"></span>
          `
        }

        let plotLineStyle = 'dotted'

        if ('dashStyle' in pb.options) {
          if (pb.options.dashStyle === 'Solid') plotLineStyle = 'solid'
        } else {
          plotLineStyle = 'solid'
        }

        return `
              <tr>
                <td>
                  <div style="border-top:${plotLineWidth}px ${plotLineStyle} ${plotLineColor}; width: 24px; height: 0px; margin: 0 7px 2px 0;"></div>
                </td>
                <td colspan="2"> ${pb.options.tooltipDisplay(ctx)}</td>
              </tr>
            `
      },
    )

    template += stuffToAddToTemplate.join('')
  }

  return template
}
