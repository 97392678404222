import type { ReactNode } from 'react'

export const Tail = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={{
        padding: '0 5px 5px 5px',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          boxShadow: '0 0 5px -2px rgba(0, 0, 0, 0.38)',
          backgroundColor: 'white',
          borderRadius: '0 0 15px 15px',
          borderTop: '1px rgba(226, 226, 226, 1) solid',
        }}
      >
        {children}
      </div>
    </div>
  )
}
