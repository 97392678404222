import { useContext, useState } from 'react'
import { AlmaContext } from '../AlmaContext/AlmaContext'
import { AlmaResponse } from '../AlmaResponse/AlmaResponse'
import { CustomerMessage } from '../CustomerMessage/CustomerMessage'
import { UpArrowIcon } from '../UpArrowIcon/UpArrowIcon'

export const AlmaInput = () => {
  const [value, setValue] = useState('')
  const { setChatLog, scrollToBottomOfChat } = useContext(AlmaContext)

  return (
    <form
      css={{
        display: 'flex',
        alignItems: 'center',
        height: '60px',
      }}
      onSubmit={(e) => {
        e.preventDefault()

        if (value.length > 0) {
          setChatLog?.((log) => [
            ...log,
            <CustomerMessage textContent={value} />, // eslint-disable-line react/jsx-key
            <AlmaResponse userInput={value} />, // eslint-disable-line react/jsx-key
          ])

          scrollToBottomOfChat?.()

          setValue('')
        }
      }}
    >
      <input
        type="text"
        placeholder="Start typing..."
        value={value}
        onChange={(e) => setValue(e.target.value)}
        css={{
          'border': 'none',
          'padding': '10px',
          'flexBasis': '90%',
          'marginLeft': '10px',
          ':focus-visible': {
            outline: 'none',
          },
        }}
      />
      <button
        css={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <UpArrowIcon isActive={value.length > 0} />
      </button>
    </form>
  )
}
