import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'

export const navigatePerLlm = (llmResponse: routes.AlmaChatLlm.Response | null) => {
  if (!llmResponse) return

  // set view selected
  selectedValueGroupsStore.setState((s) => {
    if (!llmResponse.views) return s

    if (!llmResponse.property_id) return s

    const llmResponseView = llmResponse.views[0] as TValueGroup

    return {
      ...s,
      primaryValueGroup: llmResponseView,
      selectedValueGroups: llmResponse.views.reduce<Partial<Record<TValueGroup, number>>>((memo, view) => {
        if (view === llmResponseView) {
          memo[view as TValueGroup] = +new Date() + 1
        } else {
          memo[view as TValueGroup] = +new Date()
        }

        return memo
      }, {}),
    }
  })

  // set property selected
  setSelectedFieldAsset({ property: llmResponse.property_id as number })

  // open details panel according to date range selected
  detailsPanelStore.setState((s) => {
    const newState = {
      ...s,
      detailsTab: 'DETAILS',
      isForcedOpen: true,
    }

    if (!llmResponse.date_range) return newState

    return {
      ...newState,
      dateFrom: `${llmResponse.date_range.date_range_start}T00:00:00`,
      dateTo: `${llmResponse.date_range.date_range_end}T23:59:59`,
    }
  })
}
