import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { PrecipitationTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/PrecipitationTitleChildren/PrecipitationTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  doesSelectedPointHaveValueTypes,
  doesSelectedRegionHaveValueTypes,
} from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabled } from 'utils/useIsRegionDataEnabled'
import {
  defaultValuesRowHeight,
  regionsTimestampToValuesDictionaryMaker,
  weatherTimestampToValuesDictionaryMaker,
} from '../_utils'

const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const pointCategory: TPointCategory = 'outOfBlockPoint'
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  precipitation: preferredAggregationInterval,
}

const getRegionalValuesRequested = ({
  selectedFieldAssets,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const selectedRegion = selectedFieldAssets.region

  if (!selectedRegion) return {}

  if (!doesSelectedRegionHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getValuesRequestedForPoints = ({
  selectedFieldAssets,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets[pointCategory]) return {}

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.precipitation) return {}

  const showRegionalData = isRegionDataEnabled()
  const pointsToRequest = getValuesRequestedForPoints({ selectedFieldAssets })
  const regionsToRequest = showRegionalData ? getRegionalValuesRequested({ selectedFieldAssets }) : {}

  return { ...pointsToRequest, ...regionsToRequest }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.precipitation().categoryTitleWithoutUnit(),
    titleChildren: (
      <PrecipitationTitleChildren
        showAggregationToggle={false}
        valuesTimeseriesToFilterOn={Object.keys(valuesRequested)}
      />
    ),
    id: 'summary-grid-precipitation',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = String(selectedFieldAssets[pointCategory])
  const selectedRegion = selectedFieldAssets.region

  const showRegionalData =
    isRegionDataEnabled() &&
    doesSelectedRegionHaveValueTypes({
      valuesTimeseries: ['dewPoint_IN'],
    })

  const values = weatherTimestampToValuesDictionaryMaker(data, 'precipitation', stationLngLat)
  const items: GridTableContentSection['items'] = []

  if (!isUserOnlyAFreeRegionalUser()) {
    items.push({
      id: 'rain',
      label: unitConverter.precipitation().shortTitle(),
      height: defaultValuesRowHeight,
      labelMinWidth: 90,
      valueMinWidth: 120,
      render: (dataPoint: { value: { sum: number } }) => {
        if (typeof dataPoint?.value?.sum !== 'number') {
          return translate.phrases.templates('-')
        }

        return unitConverter.precipitation(dataPoint?.value?.sum).valueWithSuffix()
      },
      values,
    })
  }

  if (showRegionalData && selectedRegion) {
    const label = selectedFieldAssets.property
      ? fieldAssetStore.getState().regions?.[selectedRegion]?.name
      : unitConverter.precipitation().shortTitle()

    items.push({
      id: 'rain-regional',
      label,
      height: defaultValuesRowHeight,
      labelMinWidth: 90,
      valueMinWidth: 120,
      render: (dataPoint: { value: { sum: number } }) => {
        if (typeof dataPoint?.value?.sum !== 'number') {
          return translate.phrases.templates('-')
        }

        return unitConverter.precipitation(dataPoint?.value?.sum).valueWithSuffix()
      },
      values: regionsTimestampToValuesDictionaryMaker(data, 'precipitation', selectedRegion),
    })
  }

  return {
    ...commonReturnItems,
    items,
  }
}
