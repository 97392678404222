import { AlmaIcon } from '../AlmaIcon/AlmaIcon'
import { EllipsisIcon } from '../EllipsisIcon/EllipsisIcon'

export const AlmaMessage = ({
  showAvatar,
  ...props
}: { showAvatar?: boolean } & ({ textContent: string } | { showEllipsis: boolean })) => {
  return (
    <div
      css={{
        display: 'flex',
        marginRight: '10%',
      }}
    >
      <div
        css={{
          color: 'rgba(255, 255, 255, 1)',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          padding: '10px',
          borderRadius: '0 15px 15px 15px',
          position: 'relative',
        }}
      >
        {showAvatar ? (
          <div
            css={{
              position: 'absolute',
              left: '-28px',
              top: '0',
            }}
          >
            <AlmaIcon />
          </div>
        ) : null}

        {'showEllipsis' in props && <EllipsisIcon />}
        {'textContent' in props && props.textContent}
      </div>
    </div>
  )
}
