export const sampleCollection = <T>({ collection, maxSize }: { collection: T[]; maxSize: number }): T[] => {
  if (collection.length <= maxSize) return collection

  const indices = new Set<number>()

  while (indices.size < maxSize) {
    indices.add(Math.floor(Math.random() * collection.length))
  }

  return Array.from(indices).map((i) => collection[i])
}
