import { Switch } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { toggleValueGroups } from 'stores/selectedValueGroupsStore/toggleValueGroups'

export const NdviSwitch = () => {
  const isChecked = selectedValueGroupsStore.useSelector((s) => !!s.selectedValueGroups.ndvi)

  return (
    <Switch
      label={translate.phrases.banyanApp('Vegetation')}
      checked={isChecked}
      onChange={(event) => {
        if (event.target.checked) {
          toggleValueGroups(['ndvi'], true)
        } else {
          toggleValueGroups(['ndvi'], false)

          if (mapSelectedCardStore.getState().metaNdvi) {
            mapSelectedCardStore.actions.hide()
          }
        }
      }}
      size="xs"
      styles={{
        label: {
          color: colors.white,
          cursor: 'pointer',
          fontSize: 13,
          paddingLeft: '0.5rem',
        },
        track: {
          cursor: 'pointer',
          backgroundColor: isChecked ? `${colors.green} !important` : undefined,
          borderColor: isChecked ? `${colors.green} !important` : undefined,
        },
      }}
    />
  )
}
