import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faBackwardStep, faForwardStep, faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, LoadingOverlay, Slider } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { openModal } from '@mantine/modals'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { MOMENTJS_ISO_WITHOUT_THE_ZED_STRING_FORMAT } from '@semios/app-platform-common'
import { EAggregationInterval } from '@semios/app-platform-value-type-definitions'
import * as Sentry from '@sentry/react'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapSearchStore } from 'stores/mapSearchStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { apiFetch } from 'utils/apiFetch'
import { isAggregatedType } from 'utils/isAggregatedValueType'
import { showNotification } from 'utils/showNotification'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useScreenSize } from 'utils/useScreenSize'
import { MapControls } from '../MapControls/MapControls'
import { DropdownSelectorProperty } from '../PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { ValuesMap } from './ValuesMap'

export const PropertyPlayback = () => {
  const [newDatesSelected, setNewDatesSelected] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const playbackSpeed = 0.5
  const [valueTypeData, setValueTypeData] = useState<routes.Values.Response['heatmapPoints']>({})
  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const selectedPropertyFieldAsset = selectedFieldAssetsStore.useSelector((s) => s?.property)

  const mapSearchSelectedProperty = mapSearchStore.useSelector(
    (s) => Object.keys(s.highlightedPropertyIds)[0],
  )

  const selectedPropertyId: string | number = mapSearchSelectedProperty ?? selectedPropertyFieldAsset
  const selectedProperty = selectedPropertyId ? properties?.[Number(selectedPropertyId)] : undefined
  const centroidCoordinates = selectedProperty?.centroid.coordinates
  const propertyBlocks = selectedProperty?.blocks
  const propertyBlockIds = propertyBlocks ? Object.keys(propertyBlocks).map((blockId) => Number(blockId)) : []

  const timezone = selectedPropertyId
    ? String(properties?.[Number(selectedPropertyId)].timezone)
    : moment.tz.guess()

  const mapRef = useRef<google.maps.Map | null>(null)
  const primaryValueGroup = usePrimaryValueGroup() || 'air_temperature'
  const aggregationInterval = EAggregationInterval.UNAGGREGATED
  const [dataKey, setDataKey] = useState(0)

  const { maxDate, defaultDate, isBeforeNoonToday, now } = useMemo(() => {
    // Get current time in property timezone
    const propertyTime = moment.tz(timezone)
    const propertyToday = propertyTime.clone().startOf('day')
    const isBeforeNoonInProperty = propertyTime.get('hour') < 12

    let defaultDate = propertyToday.format('lll')

    if (isBeforeNoonInProperty) {
      // When before noon in property timezone, use previous day
      defaultDate = propertyToday.subtract(1, 'day').format('lll')
    }

    return {
      maxDate: defaultDate,
      defaultDate: defaultDate,
      isBeforeNoonToday: isBeforeNoonInProperty,
      now: propertyTime,
    }
  }, [timezone])

  const [selectedDate, setSelectedDate] = useState<string | null>(defaultDate)

  useEffect(() => {
    if (selectedPropertyId && selectedDate) {
      fetchHeatmapData(new Date(selectedDate))
    }
  }, [selectedPropertyId])

  useEffect(() => {
    resetAll()
  }, [mapSearchSelectedProperty])

  const formatDateTime = (timestamp: Date | string) => {
    const momentTime = moment.tz(timestamp, timezone)
    const timeFormat = translate.dates.format(momentTime, 'h:mm a (z)')
    const languageLabel = translate.dates.format(momentTime, 'ddd, MMM D')

    let timeStr = timeFormat

    if (momentTime.get('minute') === 0) {
      if (momentTime.get('hour') === 0) {
        timeStr = translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Midnight'),
          labelB: momentTime.format('z'),
        })
      } else if (momentTime.get('hour') === 12) {
        timeStr = translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Noon'),
          labelB: momentTime.format('z'),
        })
      }
    }

    return `${languageLabel}, ${timeStr}`
  }

  const sliderData = useMemo(() => {
    // Get unique timestamps from the data
    const timestamps = new Set<string>()

    let forecastStartTime: string | null | undefined

    if (valueTypeData) {
      Object.values(valueTypeData).forEach((pointData) => {
        // Get the forecast start time
        forecastStartTime = pointData.values?.temperature_IN?.[0]?.metadata?.forecastStartsAt

        pointData.values?.temperature_IN?.[0]?.timeseries?.forEach((ts) => {
          // Only add timestamp if it's before the forecast start time
          if (!forecastStartTime || moment.tz(ts.timestamp, timezone).isBefore(forecastStartTime)) {
            timestamps.add(ts.timestamp)
          }
        })
      })
    }

    const sortedTimestamps = Array.from(timestamps).sort()
    const numberOfSteps = sortedTimestamps.length - 1
    const marks: { value: number; label: string }[] = []

    if (sortedTimestamps.length > 0) {
      const indices = [
        0,
        Math.floor(numberOfSteps * 0.25),
        Math.floor(numberOfSteps * 0.5),
        Math.floor(numberOfSteps * 0.75),
        numberOfSteps,
      ]

      indices.forEach((index) => {
        const timestamp = sortedTimestamps[index]
        const isEndpoint = index === 0 || index === numberOfSteps

        marks.push({
          value: index,
          label: isEndpoint ? formatDateTime(timestamp) : '',
        })
      })
    }

    return {
      startTime: moment.tz(selectedDate, timezone).startOf('day'),
      endTime: moment.tz(selectedDate, timezone).endOf('day'),
      marks,
      numberOfSteps,
      timestamps: sortedTimestamps,
    }
  }, [selectedDate, valueTypeData, timezone])

  const [sliderValue, setSliderValue] = useState(0)

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    if (isPlaying && sliderData.numberOfSteps > 0) {
      intervalId = setInterval(() => {
        setSliderValue((currentValue) => {
          if (currentValue >= sliderData.numberOfSteps) {
            setIsPlaying(false)

            return 0
          }

          return currentValue + 1
        })
      }, playbackSpeed * 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isPlaying, playbackSpeed, sliderData.numberOfSteps])

  const PlaybackControls = () => {
    return (
      <div css={{ display: 'flex', alignItems: 'center', gap: '20px', paddingTop: '5px' }}>
        <ActionIcon onClick={() => setSliderValue(0)} css={{ padding: '5px 10px', color: 'black' }}>
          <FontAwesomeIcon icon={faBackwardStep} />
        </ActionIcon>
        <ActionIcon onClick={() => setIsPlaying(!isPlaying)} css={{ padding: '5px 10px', color: 'black' }}>
          {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
        </ActionIcon>
        <ActionIcon
          onClick={() => setSliderValue(sliderData.numberOfSteps)}
          css={{ padding: '5px 10px', color: 'black' }}
        >
          <FontAwesomeIcon icon={faForwardStep} />
        </ActionIcon>
      </div>
    )
  }

  const getTimeFromSliderValue = useCallback(
    (value: number) => {
      if (!selectedDate || !sliderData.timestamps.length) {
        return new Date()
      }

      const timestamp = sliderData.timestamps[value]

      return moment.tz(timestamp, timezone).toDate()
    },
    [selectedDate, sliderData, timezone],
  )

  const { isWideScreen } = useScreenSize()

  const datePropertySelectorContainerStyles: React.CSSProperties = {
    position: 'fixed',
    margin:
      'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    top: 77,
    ...(isWideScreen
      ? {
          right: 20,
        }
      : {
          left: '50%',
          transform: 'translateX(-50%)',
        }),
    display: 'flex',
    alignItems: 'center',
  }

  const timeSliderContainerStyles: React.CSSProperties = {
    position: 'fixed',
    margin:
      'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    top: 'auto',
    right: 0,
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    padding: '6px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: '10px',
    width: isWideScreen ? '80%' : '100%',
  }

  const resetAll = () => {
    setSliderValue(0)

    setValueTypeData({})

    setSelectedDate(null)
  }

  const fetchHeatmapData = useCallback(
    async (date: Date) => {
      setNewDatesSelected(true)

      resetAll()

      try {
        const selectedMoment = moment.tz(date, timezone)
        const isSelectedDateToday = selectedMoment.isSame(moment.tz(timezone), 'day')
        // Set start time to noon of selected date
        const startTime = selectedMoment.clone().hour(12).minute(0).second(0).millisecond(0)

        // Set end time based on scenarios
        let endTime

        if (isSelectedDateToday && isBeforeNoonToday) {
          // Case a: Today before noon - use current time
          endTime = now
        } else if (isSelectedDateToday) {
          // Case b: Today after noon - use current time
          endTime = now
        } else {
          // Case c: Historical date - use 11:50 AM next day
          endTime = selectedMoment.clone().add(1, 'day').hour(11).minute(50)
        }

        const data = await apiFetch<routes.Values.Request, routes.Values.Response>({
          url: routes.Values.path,
          params: { timeout: 6000000 },
          body: {
            dateFrom: startTime.format(MOMENTJS_ISO_WITHOUT_THE_ZED_STRING_FORMAT),
            dateTo: endTime.format(MOMENTJS_ISO_WITHOUT_THE_ZED_STRING_FORMAT),
            includeSource: true,
            heatmapPoints: {
              blockIds: propertyBlockIds,
              valuesRequested: {
                temperature_IN: { preferredAggregationInterval: aggregationInterval },
              },
            },
          },
        })

        setSelectedDate(moment.tz(date, timezone).format('lll'))

        if (!data.heatmapPoints) {
          openModal({
            styles: {
              root: {
                padding: 10,
              },
            },
            title: <strong>{translate.phrases.banyanApp('Data unavailable for selected property')}</strong>,
            children: translate.phrases.banyanApp(
              'The selected property does not have historical data for this value. Select a different property or data value.',
            ),
          })
        } else {
          let newMin = Infinity
          let newMax = -Infinity

          Object.values(data.heatmapPoints).forEach((pointData) => {
            pointData.values?.temperature_IN?.[0]?.timeseries?.forEach((ts) => {
              const value = isAggregatedType(ts.value) ? ts.value.average : ts.value

              if (value !== null && value !== undefined) {
                newMin = Math.min(newMin, value)

                newMax = Math.max(newMax, value)
              }
            })
          })

          mapControlsStore.setState((s) => ({
            ...s,
            heatmapExtremes: {
              ...s.heatmapExtremes,
              min: newMin,
              max: newMax,
            },
            manualExtremes: true,
          }))

          setValueTypeData(data.heatmapPoints)
        }

        setDataKey((prevKey) => prevKey + 1)
      } catch (error) {
        Sentry.captureException(error)

        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('There was an error when fetching the data.'),
        })
      } finally {
        setNewDatesSelected(false)
      }
    },
    [timezone, propertyBlockIds, showNotification],
  )

  return (
    <>
      <WideHeader
        title={translate.phrases.banyanApp('Property Playback')}
        onClose={() => {
          smallStore.setState((s) => ({ ...s, showPropertyPlayback: false }))

          mapControlsStore.setState((s) => ({
            ...s,
            manualExtremes: false,
          }))
        }}
      />
      <LoadingOverlay visible={newDatesSelected} />
      <ValuesMap
        key={`${selectedPropertyId}-${dataKey}`}
        propertyBlocks={propertyBlocks}
        centroidCoordinates={centroidCoordinates}
        valueGroupData={valueTypeData}
        currentTime={getTimeFromSliderValue(sliderValue)}
        mapRef={mapRef}
        valueGroup={primaryValueGroup}
        aggregationInterval={aggregationInterval}
        newDatesSelected={newDatesSelected}
      />
      <MapControls />
      <div style={datePropertySelectorContainerStyles}>
        <div css={{ marginTop: 2, marginRight: isWideScreen ? 10 : 28 }}>
          <DropdownSelectorProperty />
        </div>
        <DatePickerInput
          rightSection={<FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />}
          style={{
            width: isWideScreen ? '180px' : '150px',
            height: '33px',
            fontSize: !isWideScreen ? '11px' : '14px',
            fontWeight: 'bold',
          }}
          value={selectedDate ? new Date(selectedDate) : new Date(defaultDate)}
          valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
          maxDate={maxDate ? new Date(maxDate) : new Date()}
          onChange={(date: Date) => {
            setSelectedDate(moment.tz(timezone).format('lll'))

            fetchHeatmapData(date)
          }}
        />
      </div>
      {valueTypeData && Object.keys(valueTypeData).length > 0 && (
        <div style={timeSliderContainerStyles}>
          <Slider
            css={{
              bottom: '-10px',
            }}
            styles={{
              thumb: {
                border: '0.15rem solid black',
              },
              bar: {
                height: '0.5rem',
                backgroundColor: 'black',
              },
              track: {
                '&::before': {
                  backgroundColor: '#afaaaa',
                },
              },
              mark: {
                height: '1.5rem',
                width: '2px',
                backgroundColor: '#afaaaa',
                border: 'none',
              },
              markLabel: {
                fontSize: '12px',
                color: 'black',
                width: isWideScreen ? 'auto' : '60px',
                whiteSpace: isWideScreen ? 'nowrap' : 'normal',
              },
              markWrapper: {
                top: '-2px',
              },
            }}
            label={(value) => formatDateTime(getTimeFromSliderValue(value))}
            size={'md'}
            marks={sliderData.marks}
            w={isWideScreen ? '80%' : '85%'}
            min={0}
            max={sliderData.numberOfSteps}
            step={1}
            value={sliderValue}
            onChange={(newValue) => {
              setSliderValue(newValue)
            }}
            showLabelOnHover={false}
          />
          <PlaybackControls />
          <div css={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'bold' }}>
            {formatDateTime(getTimeFromSliderValue(sliderValue))}
          </div>
        </div>
      )}
    </>
  )
}
