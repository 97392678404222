import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import type { GeoJSONGeometry } from 'wellknown'
import { parse as parseWKT } from 'wellknown'
import type { TPropertyOverviewWithLocation } from '../../types'

export const serviceCenterPropertiesOverviewGet = async (
  propertyIds: number[],
): Promise<Record<number, TPropertyOverviewWithLocation>> => {
  const propertiesOverview = await apiFetch<
    routes.ServiceCenterPropertiesSummaryGet.Request,
    routes.ServiceCenterPropertiesSummaryGet.Response
  >({
    url: routes.ServiceCenterPropertiesSummaryGet.path,
    body: { propertyIds },
    params: {
      method: 'POST',
    },
  })

  const shapedPropertySummaries: Record<number, TPropertyOverviewWithLocation> = {}

  for (const propertyId in propertiesOverview) {
    const { center, ...rest } = propertiesOverview[propertyId]

    shapedPropertySummaries[propertyId] = {
      ...rest,
      propertyId: Number(propertyId),
      point: parseWKT(center) as GeoJSONGeometry,
    }
  }

  return shapedPropertySummaries
}
