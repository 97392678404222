import { routes } from '@semios/app-platform-banyan-route-definitions'
import { useContext, useMemo, useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { AlmaContext } from '../AlmaContext/AlmaContext'
import { AlmaMessage } from '../AlmaMessage/AlmaMessage'
import { AlmaPill } from '../AlmaPill/AlmaPill'
import { AlmaResponse } from '../AlmaResponse/AlmaResponse'
import { CustomerMessage } from '../CustomerMessage/CustomerMessage'
import { PropertySelection } from '../PropertySelection/PropertySelection'
import { generateRandomLayers } from './utils/generateRandomLayers'

export const LayerSelection = () => {
  const [showSelection, setShowSelection] = useState(true)

  const { sessionId, setSelectedLayer, hasOnlyOneProperty, setChatLog, scrollToBottomOfChat } =
    useContext(AlmaContext)

  const randomLayers = useMemo(generateRandomLayers, [])

  if (!sessionId) return null

  return (
    <>
      <AlmaMessage textContent="Start by typing what you want to look at for a specific property or start with some of the easy topics below." />
      {showSelection && (
        <div
          css={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          {randomLayers.map((layer) => (
            <AlmaPill
              key={layer.key}
              textContent={layer.translatedTitle}
              detail={layer}
              onClick={(layer) => {
                setShowSelection(false)

                setSelectedLayer?.(layer)

                apiFetch({
                  url: routes.AlmaChatLlm.path,
                  body: {
                    session_id: sessionId,
                    user_info: {
                      default_views: [layer.translatedTitle],
                    },
                  },
                })

                setChatLog?.((log) => [
                  ...log,
                  <CustomerMessage textContent={layer.translatedTitle} />, // eslint-disable-line react/jsx-key
                  hasOnlyOneProperty ? (
                    <AlmaResponse userInput={layer.translatedTitle} /> // eslint-disable-line react/jsx-key
                  ) : (
                    <PropertySelection /> // eslint-disable-line react/jsx-key
                  ),
                ])

                scrollToBottomOfChat?.()
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}
